export const SET_FORM = 'SET_FORM';
export const FORM_UPDATE_FIELD = 'FORM_UPDATE_FIELD'
export const GET_FORM_SUCCESS = 'GET_FORM_SUCCESS'
export const GET_FORM_ERROR = 'GET_FORM_ERROR'
export const FORM_UPDATE_ERROR = 'FORM_UPDATE_ERROR'
export const FORM_UPDATE_SUCCESS = 'FORM_UPDATE_SUCCESS'
export const FORM_SUBMIT_ERROR = 'FORM_SUBMIT_ERROR'
export const FORM_SUBMIT_SUCCESS = 'FORM_SUBMIT_SUCCESS'
export const RESET_FORM = 'RESET_FORM'
export const INIT = { 
    finished: {
        value: false,
        valid: true
    },
    previousPatientId: '',
    savedProviderInfo: {
      value: '',
      valid: true
    },
};

export default function(state = INIT, { type, payload }) {

  switch(type) {

    case SET_FORM:
      // case for clearing user data
      if(JSON.stringify(payload) === JSON.stringify({})) {
        return payload;
      }
      // otherwise merge new user changes to state
      return Object.assign({}, state, payload);
    case FORM_UPDATE_FIELD:
    case RESET_FORM:
    case GET_FORM_SUCCESS:
        return payload;
    case FORM_UPDATE_SUCCESS:
    case FORM_UPDATE_ERROR:
    case FORM_SUBMIT_SUCCESS:
    case FORM_SUBMIT_ERROR:
    case GET_FORM_ERROR:
    default:
      return state;
  }
}
