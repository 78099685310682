import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import {
    Typography,
    IconButton
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import styles from './styles';
import { EventColors } from '../../../../../styles';

const Modal = props => {
    const { classes, event, onDelete } = props;

    return (
        <div className={classes.root}>
            <div className={classes.eventHeader} style={{backgroundColor: EventColors[event.type]}}>
                <Typography variant={"h6"} className={classes.eventText}> {event.type} </Typography>
                <IconButton
                    aria-label="remove-cpt"
                    onClick={onDelete}
                    className={classes.eventRemoveIcon}>
                    <CloseIcon style={{color: 'white', fontSize: 16}}/>
                </IconButton>
            </div>
            <div className={classes.eventAttributes}>
                <Typography variant={"h6"} className={classes.eventSubText}> {event.category} </Typography>
                <Typography variant={"h6"} className={classes.eventSubText}> {event.timeFrame} </Typography>
            </div>
        </div>
    );
};

export default withStyles(styles)(Modal);
