import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles'

import { Visibility, VisibilityOff } from '@material-ui/icons';
import { InputAdornment } from '@material-ui/core';

import styles from './styles';

class Input extends React.Component {
  

  constructor(props) {
    super(props);
    this.state = {
      inputType: props.type || 'text'
    }
  }

  toggleType() {
    this.setState({
      inputType: this.state.inputType === "text" ? "password" : "text"
    })
  }

  render() {
    const { 
      classes, 
      disableUnderline, 
      error, 
      errorMessage, 
      validator, 
      marginBottom, 
      InputLabelProps, 
      type, 
      fullWidth=true, 
      ...rest 
    } = this.props;

    if(type === "password") {
      if(!rest.InputProps) {
        rest.InputProps = {};
      }
      rest.InputProps.endAdornment = (
        <InputAdornment className={classes.visibility} position="end">
          { 
            this.state.inputType === 'password' ? 
            <Visibility onClick={this.toggleType.bind(this)}/> : 
            <VisibilityOff onClick={this.toggleType.bind(this)}/>
          }
        </InputAdornment>
      )
    }

    return (
      // set defaults for text fields app-wide
      <TextField
        type={this.state.inputType}
        fullWidth={fullWidth}
        style={marginBottom ? {marginBottom} : {}}
        error={!!error}
        InputProps={{
          disableUnderline
        }}
        InputLabelProps={{
          className: classes.label,
          ...InputLabelProps
        }}
        {...rest}
      />
    );
  }
}

export default withStyles(styles)(Input);
