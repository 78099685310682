/**
 * 
 * @param {Initial Object} init 
 * @param {Object being used} state 
 */
function getKeyObject(init, state) {
    Object.keys(state).forEach(key => {
      if(init[key]) {
        init[key].value = state[key] ? state[key] : ""
      }
      else {
        init[key] = state[key]
      }
    })

    return init;
}

export default getKeyObject;