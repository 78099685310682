const checkError = (error) => {
  console.log("Check Error", error);
  if (Array.isArray(error.errors)) {
    return error.errors.map(err => err.message).join(', ');
  }
  if (typeof error === 'string') {
    return error;
  }
  if (error.message) {
    return error.message;
  }
  if (error.response) {
    if(error.response.data) {
      return error.response.data.message;
    }
  }

  return 'Unknown error';
};

export default checkError;
