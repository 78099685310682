import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import CancelIcon from '@material-ui/icons/Cancel';
import { IconButton } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
// Redux
import { setSnackbar } from '../../../redux/actions/snackbar';
import { setSearchFormId, toggleSearchForm } from '../../../redux/actions/searchForm';
// Components
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import Page from '../../../components/Page/Page';
import FormTable from './FormTable/FormTable';

import styles from './styles';

const Dashboard = (props) => {
	const { classes, setSearchFormId, toggleSearchForm, searchForm } = props;

	useEffect(() => {
		return () => {
			setSearchFormId('');
			toggleSearchForm(false);
		};
	}, []);

	function handleError(error) {
		setSnackbar(error.message);
	}

	return (
		<React.Fragment>
			<Collapse in={searchForm.open}>
				<Input
					name='search'
					variant='filled'
					placeholder='Search Form ID'
					InputProps={{
						endAdornment: !!searchForm.id ? (
							<InputAdornment position='end'>
								<IconButton onClick={() => setSearchFormId('')}>
									<CancelIcon />
								</IconButton>
							</InputAdornment>
						) : null,
						disableUnderline: true,
						classes: { input: classes.input },
					}}
					onChange={(e) => setSearchFormId(e.target.value)}
					value={searchForm.id}
				/>
			</Collapse>
			<Page classes={{ root: classes.root }}>
				<Button
					text='New Form'
					onClick={() => props.history.push('/form/new')}
					color='primary'
					size='large'
					fullWidth={true}
					variant='contained'
				/>
				<FormTable
					finished={'false'}
					title='Unfinished Forms'
					onError={handleError}
					history={props.history}
					search={searchForm.id}
				/>
				<FormTable
					finished={'true'}
					title='Finished Forms'
					onError={handleError}
					history={props.history}
					search={searchForm.id}
				/>
			</Page>
		</React.Fragment>
	);
};

const mapStateToProps = ({ setSnackbar, searchForm }) => ({ setSnackbar, searchForm });
const mapDispatchToProps = { setSearchFormId, toggleSearchForm };

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Dashboard));
