const admissionStatuses = [
    "Ambulatory",
    "Observation",
    "Inpatient"
]

const procedureStatuses = [
    "Elective",
    "Urgent",
    "Emergent"
]

const providerTitles = [
    'Dentist Anesthesiologist',
    'Resident (Dentist Anesthesiologist)',
    'Advanced Practice Nurse',
    'Anesthesiologist',
    'Certified Anesthesiologist Assistant',
    'Certified Registered Nurse Anesthetist',
    'Fellow (Anesthesiology)',
    'Surgeon',
    'Physician Assistant',
    'Registered Nurse',
    'Resident (Anesthesia)',
    'Student Registered Nurse Anesthetist',
    'Dentist or Oral Surgeon',
    'Podiatrist',
    'Resident (Oral and Maxillofacial Surgeon)'
]

const coverageCodes = [
    "DA",
    "DA-DA",
    "MD-DA"
]

const locations = [
    "Office",
    "Surgicenter",
    "Hospital",
    "School"
]

module.exports = {
    admissionStatuses,
    procedureStatuses,
    providerTitles,
    coverageCodes,
    locations
}
