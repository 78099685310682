const styles = theme => ({
    adornment: {
        "whiteSpace": 'nowrap',
        "marginLeft": '10px'
    },
    filledColor: {
        backgroundColor: '#ededed'
    }
});

export default styles;