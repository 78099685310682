const styles = theme => ({
    title: {
        fontSize: 14,
        fontWeight: 700,
        fontfamily: 'Roboto',
        marginBottom: 10,
    },
    inputLabel: {
        fontSize: 14,
    },
    root: {
        
    },
    form: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    asterisk: {
        color: 'red',
    }
});

export default styles;