import { API } from 'aws-amplify';
import cloneDeep from 'lodash.clonedeep';
import getKeyObject from '../../modules/get-key-object';

import {
    INIT,
    SET_FORM,
    FORM_UPDATE_FIELD,
    FORM_SUBMIT_SUCCESS,
    GET_FORM_ERROR,
    GET_FORM_SUCCESS,
    FORM_SUBMIT_ERROR,
    RESET_FORM,
    FORM_UPDATE_ERROR,
    FORM_UPDATE_SUCCESS,
} from '../reducers/form';
import { INIT as INIT_PATIENT_FORM } from '../reducers/patientInfoForm';
import { INIT as INIT_PROVIDER_FORM } from '../reducers/providerInfoForm';
import { INIT as INIT_EVENT_FORM } from '../reducers/eventInfoForm';
import { set as setSavedProviderInfo } from './savedProviderInfo';
import { reset as resetPatientForm, set as setPatientForm } from './patientInfoForm';
import { reset as resetProviderForm, set as setProviderForm } from './providerInfoForm';
import { reset as resetEventForm, set as setEventForm } from './eventInfoForm';
import removeEmptyOrNullValues from '../../modules/remove-empty';
import moment from 'moment';

export const reset = () => async (dispatch, getState) => {
    const { patientInfoForm } = getState();

    const { patientId } = patientInfoForm;

    dispatch(resetEventForm());
    dispatch(resetPatientForm());
    dispatch(resetProviderForm());

    dispatch({
        type: RESET_FORM,
        payload: Object.assign({}, INIT, { previousPatientId: patientId.value }),
    });
};

export const set = (form) => {
    if (form) {
        return {
            type: SET_FORM,
            payload: form,
        };
    }
    // Empty user in store if no user is sent
    return {
        type: SET_FORM,
        payload: INIT,
    };
};

export const get = (id) => async (dispatch) => {
    let path = `/form/${id}`;
    try {
        let response = await API.get('ASDAPatientOutcomeAPI', path);
        let { PatientInfo, ProviderInfo, EventInfo, ...Form } = response.form;

        /** Grab the local time */
        if (EventInfo.procedureStartTime) EventInfo.procedureStartTime = moment.utc(EventInfo.procedureStartTime).local();
        if (EventInfo.procedureEndTime) EventInfo.procedureEndTime = moment.utc(EventInfo.procedureEndTime).local();
        if (EventInfo.anesthesiaStartTime)
            EventInfo.anesthesiaStartTime = moment.utc(EventInfo.anesthesiaStartTime).local();
        if (EventInfo.anesthesiaEndTime) EventInfo.anesthesiaEndTime = moment.utc(EventInfo.anesthesiaEndTime).local();
        if (EventInfo.surgeryStartTime) EventInfo.surgeryStartTime = moment.utc(EventInfo.surgeryStartTime).local();
        if (EventInfo.surgeryEndTime) EventInfo.surgeryEndTime = moment.utc(EventInfo.surgeryEndTime).local();
        if (EventInfo.timeReadyDischarge)
            EventInfo.timeReadyDischarge = moment.utc(EventInfo.timeReadyDischarge).local();

        PatientInfo.dateOfBirth = moment.utc(PatientInfo.dateOfBirth).startOf('day');
        ProviderInfo.DOS = moment.utc(ProviderInfo.DOS).startOf('day');

        if (!Array.isArray(PatientInfo.icd10)) PatientInfo.icd10 = [PatientInfo.icd10];

        let providerFormPayload = getKeyObject(cloneDeep(INIT_PROVIDER_FORM), ProviderInfo);
        let patientFormPayload = getKeyObject(cloneDeep(INIT_PATIENT_FORM), PatientInfo);
        let eventFormPayload = getKeyObject(cloneDeep(INIT_EVENT_FORM), EventInfo);
        let formPayload = getKeyObject(cloneDeep(INIT), Form);

        /** Dispatch to the correct forms */
        await dispatch(setProviderForm(providerFormPayload));
        await dispatch(setPatientForm(patientFormPayload));
        await dispatch(setEventForm(eventFormPayload));

        dispatch({
            type: GET_FORM_SUCCESS,
            payload: formPayload,
        });

        return response;
    } catch (error) {
        console.log(error);
        dispatch({
            type: GET_FORM_ERROR,
        });
        throw error;
    }
};

export const submit = (finished) => async (dispatch, getState) => {
    const { providerInfoForm, patientInfoForm, eventInfoForm } = getState();

    let path = `/form`;

    let providerInfo = {};
    Object.keys(INIT_PROVIDER_FORM).forEach((key) => {
        providerInfo[key] = providerInfoForm[key].value;
    });
    removeEmptyOrNullValues(providerInfo);

    let patientInfo = {};
    Object.keys(INIT_PATIENT_FORM).forEach((key) => {
        patientInfo[key] = patientInfoForm[key].value;
    });
    removeEmptyOrNullValues(patientInfo);

    let eventInfo = {};
    Object.keys(INIT_EVENT_FORM).forEach((key) => {
        eventInfo[key] = eventInfoForm[key].value;
    });
    removeEmptyOrNullValues(eventInfo);

    let body = { finished, providerInfo, patientInfo, eventInfo };

    try {
        let response = await API.post('ASDAPatientOutcomeAPI', path, { body });

        // set saved provider info
        if (providerInfoForm.saveProviderInfo.value) {
            await dispatch(setSavedProviderInfo(providerInfoForm));
        } else {
            await dispatch(setSavedProviderInfo(null));
        }

        dispatch({
            type: FORM_SUBMIT_SUCCESS,
        });

        return response;
    } catch (error) {
        console.log(error);
        dispatch({
            type: FORM_SUBMIT_ERROR,
        });
        throw error;
    }
};

// TODO: Change URL and body if needed
export const resubmit = (id) => async (dispatch, getState) => {
    const { providerInfoForm, patientInfoForm, eventInfoForm } = getState();

    let providerInfo = {};
    Object.keys(INIT_PROVIDER_FORM).forEach((key) => {
        providerInfo[key] = providerInfoForm[key].value;
    });
    removeEmptyOrNullValues(providerInfo);

    let patientInfo = {};
    Object.keys(INIT_PATIENT_FORM).forEach((key) => {
        patientInfo[key] = patientInfoForm[key].value;
    });
    removeEmptyOrNullValues(patientInfo);

    let eventInfo = {};
    Object.keys(INIT_EVENT_FORM).forEach((key) => {
        eventInfo[key] = eventInfoForm[key].value;
    });
    removeEmptyOrNullValues(eventInfo);

    let body = { providerInfo, patientInfo, eventInfo, finished: true, resubmit: true };

    console.log(body);

    try {
        let path = `/form/${id}`;
        let response = await API.patch('ASDAPatientOutcomeAPI', path, { body });

        // set saved provider info
        if (providerInfoForm.saveProviderInfo.value) {
            await dispatch(setSavedProviderInfo(providerInfoForm));
        } else {
            await dispatch(setSavedProviderInfo(null));
        }

        dispatch({
            type: FORM_SUBMIT_SUCCESS,
        });

        return response;
    } catch (error) {
        console.log(error);
        dispatch({
            type: FORM_SUBMIT_ERROR,
        });
        throw error;
    }
};

export const update = (finished) => async (dispatch, getState) => {
    const { form, providerInfoForm, patientInfoForm, eventInfoForm } = getState();

    let path = `/form/${form.id}`;

    let providerInfo = {};
    Object.keys(INIT_PROVIDER_FORM).forEach((key) => {
        providerInfo[key] = providerInfoForm[key].value;
    });
    removeEmptyOrNullValues(providerInfo);

    let patientInfo = {};
    Object.keys(INIT_PATIENT_FORM).forEach((key) => {
        patientInfo[key] = patientInfoForm[key].value;
    });
    removeEmptyOrNullValues(patientInfo);

    let eventInfo = {};
    Object.keys(INIT_EVENT_FORM).forEach((key) => {
        eventInfo[key] = eventInfoForm[key].value;
    });
    removeEmptyOrNullValues(eventInfo);

    let body = { finished, providerInfo, patientInfo, eventInfo };
    console.log(body);

    try {
        let response = await API.patch('ASDAPatientOutcomeAPI', path, { body });

        // set saved provider info
        if (providerInfoForm.saveProviderInfo.value) {
            await dispatch(setSavedProviderInfo(providerInfoForm));
        } else {
            await dispatch(setSavedProviderInfo(null));
        }

        dispatch({
            type: FORM_UPDATE_SUCCESS,
        });

        return response;
    } catch (error) {
        console.log(error);
        dispatch({
            type: FORM_UPDATE_ERROR,
        });
        throw error;
    }
};
export const updateField = ({ Field, Value, Valid = true, ExpectedType }) => (dispatch, getState) => {
    const { form } = cloneDeep(getState());

    if (Value !== null && Value !== undefined) {
        if (typeof INIT[Field].value === typeof Value || ExpectedType === typeof Value) {
            form[Field].value = Value;
        }
    }
    form[Field].valid = Valid;

    dispatch({
        type: FORM_UPDATE_FIELD,
        payload: form,
    });

    return form;
};
