import React from 'react';
import { withStyles } from '@material-ui/styles';
import clsx from 'classnames';
// import { format } from 'date-fns'
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import styles from './styles';

class Datepicker extends React.Component {
    render() {
        const {
            classes,
            errorMessage,
            error,
            validator,
            disableUnderline,
            marginBottom,
            value,
            type,
            format,
            InputProps = {},
            borderError,
            ...rest
        } = this.props;

        if (type === 'date') {
            return (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        fullWidth={true}
                        style={marginBottom ? { marginBottom } : {}}
                        classes={{
                            root: clsx(classes.root, {
                                [classes.error]: !!error && borderError,
                            }),
                        }}
                        inputProps={{
                            classes: { root: classes.input },
                        }}
                        InputProps={{
                            disableUnderline,
                            classes: { root: classes.input },
                            ...InputProps,
                        }}
                        error={!!error}
                        invalidDateMessage={null}
                        value={value ? value : null}
                        format={format ? format : 'MM/dd/yyyy'}
                        {...rest}
                    />
                </MuiPickersUtilsProvider>
            );
        }

        return (
            // set defaults for text fields app-wide
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDateTimePicker
                    fullWidth={true}
                    style={marginBottom ? { marginBottom } : {}}
                    classes={{
                        root: clsx(classes.root, {
                            [classes.error]: !!error && borderError,
                        }),
                    }}
                    inputProps={{
                        classes: { root: classes.input },
                    }}
                    InputProps={{
                        disableUnderline,
                        classes: { root: classes.input },
                        ...InputProps,
                    }}
                    KeyboardButtonProps={{
                        classes: { root: classes.buttonRoot }
                    }}
                    invalidDateMessage={null}
                    value={value ? value : null}
                    format={format ? format : 'MM/dd/yyyy hh:mm a'}
                    {...rest}
                />
            </MuiPickersUtilsProvider>
        );
    }
}

export default withStyles(styles)(Datepicker);
