import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress'

import Table            from '@material-ui/core/Table';
import TableBody        from '@material-ui/core/TableBody';
import TablePagination  from '@material-ui/core/TablePagination';
import TableCell        from '@material-ui/core/TableCell';
import TableHead        from '@material-ui/core/TableHead';
import TableRow         from '@material-ui/core/TableRow';
import TableSortLabel   from '@material-ui/core/TableSortLabel';
// import TablePagination  from '@material-ui/core/TablePagination';
import ToolTip          from '@material-ui/core/Tooltip';

import styles from './styles';


const CustomTable = props => {
    let { count, classes, headers, rows, page, rowsPerPage, onChangePage, onChangeRows, onSort, loading } = props;

    if (typeof rowsPerPage === 'undefined') {
        rowsPerPage = 50;
    }

    const [order, setOrder] = useState(props.startOrder || 'desc');
    const [orderBy, setOrderBy] = useState(props.startOrderIndex || 0); // 2 is the index for the column 'date'

    function handleRequestSort(e, index) {
        let newOrder = order;
        if (orderBy === index) {
            if (order === 'desc') {
                newOrder = 'asc';
                setOrder(newOrder);
            } else {
                newOrder = 'desc';
                setOrder(newOrder);
            }
        }
        setOrderBy(index);

        if(typeof onSort === 'function'){
            onSort(newOrder, index);
            return;
        }
    };

    function handlePageChange(e, page) {
        if (typeof onChangePage !== 'undefined') {
            onChangePage(e, page);
        }
    }

    function handleChangeRowsPerPage(e) {
        if (typeof onChangeRows !== 'undefined') {
            onChangeRows(e);
        }
    }

    
    if(loading){
        return (
            <div className={classes.progress}>
                <CircularProgress color="primary" />
            </div>
        );
    }
    
    return (
        <React.Fragment>
            <div className={classes.tableWrapper} style={props.style}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {
                                headers.map((header, index) => (
                                    props.sorted ? 
                                    <TableCell
                                        key={`header-${index}`}>
                                        <ToolTip
                                            title="Sort"
                                            enterDelay={300}>
                                            <TableSortLabel
                                                active={orderBy === index}
                                                direction={order}
                                                onClick={e => handleRequestSort(e, index)}
                                                className={classes.tableSortLabel}>
                                                {header}
                                            </TableSortLabel>
                                        </ToolTip>
                                    </TableCell> 
                                    :
                                    <TableCell key={`header-${index}`}>
                                        {header}
                                    </TableCell>

                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            rows.map(({ cols }, index) => (
                                <TableRow key={`row-${index}`}>
                                    {
                                        cols.map((cell, cIndex) => {

                                            return (
                                                <TableCell key={`cell-${index}-${cIndex}`}>
                                                    <div>
                                                        {cell}
                                                    </div>
                                                </TableCell>
                                            );
                                        }) 
                                    }
            
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </div>
            {
                props.paginated ?
                <div className={classes.tableWrapper}>
                    <table className={classes.pagination}>
                        <tbody>
                            <tr>
                                <TablePagination
                                    count={count}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                    rowsPerPageOptions={[10,25,50,100]}
                                    onChangePage={handlePageChange}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    classes={{
                                        root: classes.paginationRoot
                                    }} />
                            </tr>
                        </tbody>
                    </table>
                </div> : ''
            }
        </React.Fragment>
    );
};

export default withStyles(styles)(CustomTable);