import { applyMiddleware, compose, createStore } from 'redux';
import { persistStore, persistReducer }          from 'redux-persist';
import thunk                                     from 'redux-thunk';
import { createBrowserHistory }                  from 'history';
import storage                                   from 'redux-persist/lib/storage';
import reducers                                  from './reducers';

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: [
    'user', 
    'cognitoUser', 
    'form', 
    'patientInfoForm', 
    'providerInfoForm', 
    'eventInfoForm', 
    'savedProviderInfo'
  ] 
};

const persistedReducer = persistReducer(persistConfig, reducers);

const enhancer = compose(
  applyMiddleware(thunk),
  process.env.NODE_ENV === 'development' &&
  typeof window !== 'undefined' &&
  typeof window.devToolsExtension !== 'undefined'
  ? // Call the brower extension function to create the enhancer.
    window.__REDUX_DEVTOOLS_EXTENSION__()
  : // Else we return a no-op function.
    f => f,
);

// export const store = Reactotron.createStore(persistedReducer, {}, enhancer);
export const store = createStore(persistedReducer, {}, enhancer);
export const history = createBrowserHistory();
// export const store = createStore(reducers, enhancer);
export let persistor = persistStore(store);


// Check purge request
async function checkForPurgePersistor() {
  let refresh = localStorage.getItem('did-purge-1')
  if(!refresh) { 
    persistor.purge();
    localStorage.setItem('did-purge-1', true);
  }
}
checkForPurgePersistor();