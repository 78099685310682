import React, { useEffect, useRef, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import CustomTable from '../../../../components/CustomTable';
import ActionCell from '../../../../components/CustomTable/ActionCell';
import Button from '../../../../components/Button';
import queryString from 'query-string';
import { Typography } from '@material-ui/core';
import { API } from 'aws-amplify';
import moment from 'moment';
import debounce from 'lodash.debounce';

let Headers = ['Created At', 'Nacor Errors', ''];
const DEBOUNCE_WAIT = 1000;

const FormTable = (props) => {
	const { classes, finished, title, onError, search } = props;

	const [_forms, _setForms] = useState({ rows: [], count: 0 });

	const [perPage, setPerPage] = useState(10);
	const [page, setPage] = useState(0);
	const [loading, setLoading] = useState(false);
	const [order, setOrder] = useState({
		orderBy: 'createdAt',
		order: 'desc',
	});

	const debouncedGetFormRef = useRef(debounce(_getForms, DEBOUNCE_WAIT)).current;

	useEffect(() => {
		debouncedGetFormRef.cancel();
		if (search) debouncedGetFormRef({}, search);
		else _getForms();
	}, [page, perPage, order, search]);

	async function _getForms(query = {}, search) {
		let path = '/form?';
		if (order.orderBy && order.order) {
			query.orderBy = order.orderBy;
			query.order = order.order;
		}

		query.page = page + 1;
		query.perPage = perPage;
		query.finished = finished;
		query.anesthesiaRecordId = search;

		path += queryString.stringify(query);

		setLoading(true);
		try {
			let response = await API.get('ASDAPatientOutcomeAPI', path);
			console.log(response.result);
			_setForms(response.result);
			setLoading(false);
		} catch (error) {
			console.log(error);
			onError(error);
			setLoading(false);
		}
	}

	function handleSort(order, index) {
		let translation = {
			'Created At': 'createdAt',
		};

		let translated = translation[Headers[index]];
		setOrder({ orderBy: translated, order });
	}

	function handleChangePage(e, page) {
		setPage(page);
	}

	function handleChangeRows(e) {
		setPerPage(e.target.value);
	}

	return (
		<div className={classes.forms}>
			<div className={classes.formsHeader}>
				<Typography className={classes.formsHeaderText} variant='h6'>
					{' '}
					{title}
				</Typography>
			</div>
			{
				<CustomTable
					paginated
					sorted
					loading={loading}
					headers={Headers}
					page={page}
					rowsPerPage={perPage}
					count={_forms.count}
					onSort={handleSort}
					onChangePage={handleChangePage}
					onChangeRows={handleChangeRows}
					rows={_forms.rows
						// .filter((row) => !search || row.id === parseInt(search))
						.map((row) => ({
							cols: [
								moment.utc(row.createdAt).local().format('MMMM DD, YYYY h:mm a'),
								row.nacorErrors ? 'Yes' : 'No',
								<ActionCell>
									<Button
										text={'Details'}
										color='primary'
										size='medium'
										onClick={(e) => props.history.replace(`/form/${row.id}`)}
									/>
								</ActionCell>,
							],
						}))}
				/>
			}
		</div>
	);
};

export default withStyles(styles)(FormTable);
