const styles = theme => ({
    title: {
        fontSize: 14,
        fontWeight: 700,
        fontfamily: 'Roboto',
        marginBottom: 10,
    },
    inputLabel: {
        fontSize: 14,
    },
    root: {
        
    },
    form: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    stepText: {
        color: '#b8b8b8',
        fontFamily: 'Roboto',
        fontSize: 15,
        fontWeight: 400,
        marginTop: 4
    },

    selectedCPT: {
        position: 'relative',
        backgroundColor: '#636b9e',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        color: 'white',
        minHeight: 56
    },
    cptCode: {
        fontFamily: 'Roboto',
        fontSize: 14,
        fontWeight: 700
    },
    cptText: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 10,
        flexGrow: 1
    },
    cptTitle: {
        fontFamily: 'Roboto',
        fontSize: 10,
        marginLeft: 10
    },
    cptRemoveIcon: {
        alignSelf: 'flex-end'
    },
    asterisk: {
        color: 'red',
    }
});

export default styles;