import React from 'react';
import { withStyles } from '@material-ui/styles';
import {
    TextField,
    MenuItem,
    Paper
} from '@material-ui/core'
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import styles from './styles'
import ChevronIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

function NoOptionsMessage(props) {
    return (
      <Typography
        color="textSecondary"
        className={props.selectProps.classes.noOptionsMessage}
        {...props.innerProps}
      >
        {props.children}
      </Typography>
    );
}

NoOptionsMessage.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    selectProps: PropTypes.object.isRequired,
};

function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
    inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};
  
function Control(props) {
    const {
        children,
        innerProps,
        innerRef,
        selectProps: { classes, disableUnderline, TextFieldProps },
    } = props;

    return (
        <TextField
            fullWidth
            InputProps={{
                inputComponent,
                inputProps: {
                    className: classes.input,
                    ref: innerRef,
                    children,
                    ...innerProps,
                },
                disableUnderline
            }}
            {...TextFieldProps}
        />
    );
}

Control.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    selectProps: PropTypes.object.isRequired,
};

function Option(props) {
    return (
    <MenuItem
        ref={props.innerRef}
        selected={props.isFocused}
        component="div"
        className={props.selectProps.classes.menuItem}
        style={{
            fontWeight: props.isSelected ? 500 : 400,
        }}
        {...props.innerProps}
    >
        {props.children}
    </MenuItem>
    );
}

Option.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    isFocused: PropTypes.bool,
    isSelected: PropTypes.bool,
};

function SingleValue(props) {
    return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
        {props.children}
    </Typography>
    );
}

SingleValue.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    selectProps: PropTypes.object.isRequired,
};

function ValueContainer(props) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

ValueContainer.propTypes = {
    children: PropTypes.node,
    selectProps: PropTypes.object.isRequired,
};

  
function Menu(props) {
    return (
        <Paper className={props.selectProps.classes.paper} {...props.innerProps}>
            {props.children}
        </Paper>
    );
}

function DropdownIndicator(props) {
    return (
        <ChevronIcon className={props.selectProps.classes.chevron} />
    )
}

Menu.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    selectProps: PropTypes.object,
};
  
const components = {
    DropdownIndicator,
    Control,
    Menu,
    NoOptionsMessage,
    Option,
    SingleValue,
    ValueContainer,
};

class TypeSelect extends React.Component {

    render(){
        const { 
            classes, 
            suggestions, 
            value, 
            onChange, 
            disableUnderline,
            defaultOptions=[],
            async, 
            loadOptions, 
            validator, errorMessage,
            ...rest 
        } = this.props

        const selectStyles = {
            input: base => ({
                ...base,
                '& input': {
                    font: 'inherit',
                },
            }),
        };

        
        if(async) {
            return (
                <AsyncSelect
                    cacheOptions
                    classes={classes}
                    disableUnderline
                    styles={selectStyles}
                    noOptionsMessage={() => "Type to search"}
                    TextFieldProps={{
                        ...rest,
                        value
                    }}
                    defaultOptions={defaultOptions}
                    loadOptions={loadOptions}
                    components={components}
                    value={{value, label: value}}
                    onChange={onChange}/>
            )
        }

        return (
            // set defaults for text fields app-wide
            <Select
                classes={classes}
                disableUnderline
                styles={selectStyles}
                noOptionsMessage={() => "Type to search"}
                TextFieldProps={{
                    ...rest,
                    value
                }}
                components={components}
                options={suggestions.map(suggestion => ({value: suggestion, label: suggestion}))}
                value={{value, label: value}}
                onChange={onChange}
            />
        );
    }
}

export default withStyles(styles)(TypeSelect);
