export const SET_FREQUENT_USED_TERMS = 'SET_FREQUENT_USED_TERMS';
export const GET_FREQUENT_USED_TERMS_SUCCESS = 'GET_FREQUENT_USED_TERMS_SUCCESS'
export const GET_FREQUENT_USED_TERMS_ERROR = 'GET_FREQUENT_USED_TERMS_ERROR'
export const INIT = {};

export default function(state = INIT, { type, payload }) {

  switch(type) {

    case SET_FREQUENT_USED_TERMS:
    case GET_FREQUENT_USED_TERMS_SUCCESS:
        return payload;
    default:
      return state;
  }
}
