export const RESET_PROVIDER_INFO_FORM = 'RESET_PROVIDER_INFO_FORM';
export const SET_PROVIDER_INFO_FORM = 'SET_PROVIDER_INFO_FORM';
export const PROVIDER_INFO_FORM_UPDATE_FIELD = 'PROVIDER_INFO_FORM_UPDATE_FIELD'
export const INIT = {
    // providerId: {
    //     value: '',
    //     valid: true,
    // },
    practiceId: {
        value: '',
        valid: true,
    },
    taxId: {
        value: '',
        valid: true,
    },
    NPI: {
        value: '',
        valid: true,
    },
    providerTitle: {
        value: '',
        valid: true
    },
    coverageCode: {
        value: '',
        valid: true,
    },
    facilityId: {
        value: '',
        valid: true
    },
    location: {
        value: '',
        valid: true
    },
    admissionStatus: {
        value: '',
        valid: true,
    },
    DOS: {
        value: '',
        valid: true,
    },
    procedureStatus: {
        value: '',
        valid: true
    },
    saveProviderInfo: {
        value: false,
        valid: true
    }
};

export default function(state = INIT, { type, payload }) {

  switch(type) {

    case SET_PROVIDER_INFO_FORM:
      // case for clearing user data
      if(JSON.stringify(payload) === JSON.stringify({})) {
        return payload;
      }
      // otherwise merge new user changes to state
      return Object.assign({}, state, payload);
    case PROVIDER_INFO_FORM_UPDATE_FIELD:
    case RESET_PROVIDER_INFO_FORM:
        return payload;
    default:
      return state;
  }
}
