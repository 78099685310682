const styles = (theme) => ({
    error: {
        border: `1px solid ${theme.palette.error.main}`,
    },
    buttonRoot: {
        color: 'white'
    }
});

export default styles;
