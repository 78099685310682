export const RESET_PATIENT_INFO_FORM = 'SET_PATIENT_INFO_FORM';
export const SET_PATIENT_INFO_FORM = 'SET_PATIENT_INFO_FORM';
export const PATIENT_INFO_FORM_UPDATE_FIELD = 'PATIENT_INFO_FORM_UPDATE_FIELD'
export const INIT = {
    patientId: {
        value: '',
        valid: true
    },
    dateOfBirth: {
        value: '',
        valid: true
    },
    age: {
        value: '',
        valid: true
    },
    gender: {
        value: '',
        valid: true
    },
    bmi: {
        value: '',
        valid: true
    },
    height: {
        value: '',
        valid: true
    },
    kilograms: {
        value: '',
        valid: true
    },
    asa: {
        value: '',
        valid: true
    },
    anesthesiaType: {
        value: '',
        valid: true
    },
    paymentMethod: {
        value: '',
        valid: true
    },
    icd10: {
        value: [],
        valid: true
    },
    CPTAnesthesiaCodes: {
        value: [],
        valid: true
    },
    CPTSurgeryCodes: {
        value: [],
        valid: true
    }
};

export default function(state = INIT, { type, payload }) {

  switch(type) {

    case SET_PATIENT_INFO_FORM:
      // case for clearing user data
      if(JSON.stringify(payload) === JSON.stringify({})) {
        return payload;
      }
      // otherwise merge new user changes to state
      return Object.assign({}, state, payload);
    case PATIENT_INFO_FORM_UPDATE_FIELD:
    case RESET_PATIENT_INFO_FORM:
        return payload;
    default:
      return state;
  }
}
