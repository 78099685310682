import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { connect } from 'react-redux';

import { setCognitoUser } from './redux/actions/cognitoUser';
import { signOut } from './redux/actions/user';

import FullscreenLoader from './components/FullscreenLoader';
import AuthenticatedStack from './containers/Authenticated';
import AuthStack from './containers/Auth';

/**
 * A HOC that routes a user to the appropriate navigation stack based on the authorizing function.
 * Must have access to the history.
 * @param {*} props 
 */

const Authorizer = props => {
  const { cognitoUser, setCognitoUser, signOut } = props;

  useEffect(() => {
    async function authenticate() {
      try {
        let userInfo = await Auth.currentUserInfo() || {};
        /** Don't call set user if the user hasn't changed, this will cause re-renders and memory leaks */
        if(userInfo.id !== cognitoUser.id) {
          throw new Error("User changed");
        }
      } catch (error) {
        await signOut();
      }
    }
    
    authenticate();
  }, [props.history.location.pathname, cognitoUser, setCognitoUser, signOut]);

  let routes;

  if (JSON.stringify(cognitoUser) === '{}') {
    routes = <AuthStack {...props} />;
  }
  else if (cognitoUser && cognitoUser.username) {
    routes = <AuthenticatedStack {...props} />;
  }
  else {
    routes = <FullscreenLoader />;
  }

  return routes;
};

const mapStateToProps = ({ cognitoUser }) => ({ cognitoUser });

export default connect(mapStateToProps, { setCognitoUser, signOut })(Authorizer);