import React, { useEffect } from 'react';
import { connect }    from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Button from '../../../components/Button';
import Page  from '../../../components/Page';
import styles from './styles';


const Confirmation = (props) => {
  const { classes, form } = props;

  useEffect(() => {
  }, []);

  
  
  return (
    <Page classes={{root: classes.root}}>
      <div className={classes.topContent}>
          <h3 className= {classes.title}>Form Submitted!</h3>
          <h4 className= {classes.subtitle}>The information for "{form.previousPatientId}" has been saved.</h4>
      </div>
      <div className={classes.bottomContent}>
        <Button 
          text="Go Back" 
          className={classes.goBackButton}
          onClick={() => props.history.replace('/')}
          color="primary"
          size="medium"
          variant="contained" 
        />
      </div>
    </Page>
  );
}

const mapStateToProps = ({ form }) => ({ form })
const actionCreators = { };

export default connect(mapStateToProps, actionCreators)(withStyles(styles)(Confirmation));