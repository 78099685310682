const anesthesiaTypes = [
    "Neuraxial",
    "General Anesthesia",
    "Monitored Anesthesia Care",
    "Peripheral Nerve Block",
    "No Anesthesia Provided",
    "Unknown",
]

const ASAClasses = [
    "I",
    "II",
    "III",
    "IV"
]

const CPTCodesAnesthesia = [
    "00170 Anesthesia for intraoral procedures",
    "00190 Anesthesia for procedures on facial bones or skull"
]

const CPTCodesSurgical = [
    "41899 (Dental treatment, incl extractions) Unlisted procedure, dentoalveolar structures",
    "20900 Bone graft",
    "21031 Excision of torus mandibularis",
    "21032 Excision of maxillary torus palatinus",
    "21120 Genioplasty",
    "21249 (Implant placement) Reconstruction of mandible or maxilla, endosteal implant",
    "21145 Reconstruction midface, LeFort I, single piece, bone graft",
    "21146 Reconstruction midface, LeFort I, 2 piece, bone graft",
    "21147 Reconstruction midface, LeFort I, 3 or more pieces, bone graft",
    "21196 (BSSO) Reconstruction of mandibular rami and/or body, Sagittal split",
    "21605 Arthrocentesis",
    "21445 Open treatment of mandibular or maxillary alveolar ridge fracture",
    "21453 Closed reduction of mandibular fracture, interdental fixation",
    "21462 Open reduction of mandibular fracture, interdental fixation",
    "21470 Open reduction of mandibular fracture, internal fixation",
    "40801 (I&D) Drainage of abscess, cyst, hematoma, vestibule of mouth",
    "40808 (Biopsy) Excision and destruction procedures, vestibule of mouth",
    "41115 (Frenectomy) Excision procedures, tongue and floor of mouth",
    "42145 (Palatopharyngoplasty) Excision and destruction procedure, palate and uvula",
    "41870 Periodontal mucosal grafting",
    "41872 Gingivoplasty",
    "41874 Alveoloplasty",
    "15780 Dermabrasion of face",
    "15819 Cervicoplasty (neck lift)",
    "15820 Blepharoplasty of the lower eyelid ",
    "15822 Blepharoplasty of the upper eyelid ",
    "15825 Rhytidectomy of neck with plastymal tightening (neck lift)",
    "15828 Rhytidectomy of chin, cheek and neck (face lift)",
    "21142 Reconstruction, LeFort I, without bone graft (includes SARPE)",
    "21248 Partial reconstruction of maxilla or mandible using endosteal or blade implant",
    "30400 Primary rhinoplasty with lateral/alar cartilages and/or elevation of nasal tip",
    "30410 Complete primary rhinoplasty with external nose",
]

const genders = [
    "Male",
    "Female",
    "Unknown"
]

const PaymentMethods = [
    "Charity",
    "Commercial",
    "Government: Medicaid",
    "Government: Medicare Fee for Service - Part A",
    "Government: Medicare Fee for Service - Part B",
    "Government: Medicare Fee for Service - Part C",
    "Government: Military/Veterans",
    "Government: Other",
    "Self-Pay",
    "Worker's Compensation",
    "Unknown",
    "Other",
]

const ICD10s = []


module.exports = {
    anesthesiaTypes,
    ASAClasses,
    CPTCodesAnesthesia,
    CPTCodesSurgical,
    genders,
    PaymentMethods,
    ICD10s
}