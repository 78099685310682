export const SET_SEARCH_FORM = 'SET_SEARCH_FORM';

export function setSearchFormId(payload) {
	return {
		type: SET_SEARCH_FORM,
		payload: {
			id: payload,
		},
	};
}

export function toggleSearchForm(payload) {
	return {
		type: SET_SEARCH_FORM,
		payload: {
			open: payload,
		},
	};
}
