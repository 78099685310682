export const SET_EVENT_INFO_FORM = 'SET_EVENT_INFO_FORM';
export const RESET_EVENT_INFO_FORM = 'RESET_EVENT_INFO_FORM';
export const EVENT_INFO_FORM_UPDATE_FIELD = 'EVENT_INFO_FORM_UPDATE_FIELD';
export const INIT = {
	procedureStartTime: {
		value: new Date(),
		valid: true,
	},
	procedureEndTime: {
		value: new Date(),
		valid: true,
	},
	anesthesiaStartTime: {
		value: new Date(),
		valid: true,
	},
	anesthesiaEndTime: {
		value: new Date(),
		valid: true,
	},
	timeReadyDischarge: {
		value: '',
		valid: true,
	},
	Events: {
		value: [],
		valid: true,
	},
};

export default function (state = INIT, { type, payload }) {
	switch (type) {
		case SET_EVENT_INFO_FORM:
			// case for clearing user data
			if (JSON.stringify(payload) === JSON.stringify({})) {
				return payload;
			}
			// otherwise merge new user changes to state
			return Object.assign({}, state, payload);
		case RESET_EVENT_INFO_FORM:
		case EVENT_INFO_FORM_UPDATE_FIELD:
			return payload;
		default:
			return state;
	}
}
