import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { updateField } from '../../../../redux/actions/providerInfoForm';
import { updateField as updateEventInfoField } from '../../../../redux/actions/eventInfoForm';

import { withStyles } from '@material-ui/core/styles';
import { Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import Input from '../../../../components/Input';
import Datepicker from '../../../../components/Input/Datepicker';
import Page from '../../../../components/Page';
import TypeSelect from '../../../../components/Input/TypeSelect';

import styles from './styles';

import { admissionStatuses, procedureStatuses, providerTitles, coverageCodes, locations } from './selects';

const ProviderInfo = (props) => {
    const { classes, providerInfoForm, eventInfoForm, updateEventInfoField, updateField, refs } = props;

    useEffect(() => {}, []);

    return (
        <Page classes={{ root: classes.root }}>
            <Grid container spacing={1}>
                {/* <Grid item xs={6} sm={6}>
                <Input
                    id='providerId'
                    ref={el => refs.providerId = el}
                    error={!providerInfoForm.providerId.valid} 
                    errorMessage={"Please provide a Provider Id"}
                    validator={() => providerInfoForm.providerId.value.length > 0}
                    InputLabelProps={{
                        classes: { root: classes.inputLabel }
                    }}
                    variant="filled"
                    disableUnderline={true}
                    onChange={(e) => updateField({Field: 'providerId', Value: e.target.value})}
                    value={providerInfoForm.providerId.value}
                    label="Provider ID:"
                />
            </Grid> */}
                <Grid item xs={6} sm={6}>
                    <Input
                        required
                        id="practiceId"
                        ref={(el) => (refs.practiceId = el)}
                        error={!providerInfoForm.practiceId.valid}
                        errorMessage={'Practice ID must be a numeric value and a length of 3 to 5 characters'}
                        validator={() => /^[0-9]{3,5}$/.test(providerInfoForm.practiceId.value)}
                        InputLabelProps={{
                            classes: { root: classes.inputLabel, asterisk: classes.asterisk },
                        }}
                        variant="filled"
                        disableUnderline={true}
                        onChange={(e) =>
                            updateField({ Field: 'practiceId', Value: e.target.value.replace(/[^\d]/, '') })
                        }
                        value={providerInfoForm.practiceId.value}
                        label="Practice ID:"
                    />
                </Grid>
                <Grid item xs={6} sm={6}>
                    <Input
                        required
                        id="taxId"
                        ref={(el) => (refs.taxId = el)}
                        error={!providerInfoForm.taxId.valid}
                        errorMessage={'Tax id must be a numeric value and a length of 9 characters'}
                        validator={() => /^[0-9]{9}$/.test(providerInfoForm.taxId.value)}
                        InputLabelProps={{
                            classes: { root: classes.inputLabel, asterisk: classes.asterisk },
                        }}
                        variant="filled"
                        disableUnderline={true}
                        onChange={(e) => updateField({ Field: 'taxId', Value: e.target.value })}
                        value={providerInfoForm.taxId.value}
                        label="Tax ID:"
                    />
                </Grid>
                <Grid item xs={6} sm={6}>
                    <Input
                        required
                        id="NPI"
                        ref={(el) => (refs.NPI = el)}
                        error={!providerInfoForm.NPI.valid}
                        errorMessage={'NPI must be a numeric value and a length of 10 characters'}
                        validator={() => /^[0-9]{10}$/.test(providerInfoForm.NPI.value)}
                        InputLabelProps={{
                            classes: { root: classes.inputLabel, asterisk: classes.asterisk },
                        }}
                        variant="filled"
                        disableUnderline={true}
                        onChange={(e) => updateField({ Field: 'NPI', Value: e.target.value })}
                        value={providerInfoForm.NPI.value}
                        label="NPI:"
                    />
                </Grid>
                <Grid item xs={6} sm={6}>
                    <TypeSelect
                        required
                        id="providerTitle"
                        ref={(el) => (refs.providerTitle = el)}
                        error={!providerInfoForm.providerTitle.valid}
                        errorMessage={'Please provide a Provider Title'}
                        validator={() => providerInfoForm.providerTitle.value.length > 0}
                        InputLabelProps={{
                            classes: { root: classes.inputLabel, asterisk: classes.asterisk },
                        }}
                        variant="filled"
                        label="Provider Title:"
                        suggestions={providerTitles}
                        disableUnderline={true}
                        onChange={(e) => updateField({ Field: 'providerTitle', Value: e.value })}
                        value={providerInfoForm.providerTitle.value}
                    />
                </Grid>
                <Grid item xs={6} sm={6}>
                    <TypeSelect
                        id="coverageCode"
                        ref={(el) => (refs.coverageCode = el)}
                        error={!providerInfoForm.coverageCode.valid}
                        errorMessage={'Please provide a Coverage Code'}
                        // validator={() => providerInfoForm.coverageCode.value.length > 0}
                        InputLabelProps={{
                            classes: { root: classes.inputLabel },
                        }}
                        variant="filled"
                        label="Coverage Code:"
                        suggestions={coverageCodes}
                        disableUnderline={true}
                        onChange={(e) => updateField({ Field: 'coverageCode', Value: e.value })}
                        value={providerInfoForm.coverageCode.value}
                    />
                </Grid>
                <Grid item xs={6} sm={6}>
                    <Input
                        required
                        id="facilityId"
                        ref={(el) => (refs.facilityId = el)}
                        error={!providerInfoForm.facilityId.valid}
                        errorMessage={'Please provide a Facility Id'}
                        validator={() => providerInfoForm.facilityId.value.length > 0}
                        InputLabelProps={{
                            classes: { root: classes.inputLabel, asterisk: classes.asterisk },
                        }}
                        variant="filled"
                        label="Facility ID:"
                        disableUnderline={true}
                        onChange={(e) => updateField({ Field: 'facilityId', Value: e.target.value })}
                        value={providerInfoForm.facilityId.value}
                    />
                </Grid>
                <Grid item xs={6} sm={6}>
                    <TypeSelect
                        id="location"
                        ref={(el) => (refs.location = el)}
                        error={!providerInfoForm.location.valid}
                        errorMessage={'Please provide a location'}
                        // validator={() => providerInfoForm.location.value.length > 0}
                        InputLabelProps={{
                            classes: { root: classes.inputLabel },
                        }}
                        variant="filled"
                        label="Location:"
                        suggestions={locations}
                        disableUnderline={true}
                        onChange={(e) => updateField({ Field: 'location', Value: e.value })}
                        value={providerInfoForm.location.value}
                    />
                </Grid>
                <Grid item xs={6} sm={6}>
                    <TypeSelect
                        id="admissionStatus"
                        ref={(el) => (refs.admissionStatus = el)}
                        error={!providerInfoForm.admissionStatus.valid}
                        errorMessage={'Please provide a Admission Status'}
                        // validator={() => providerInfoForm.admissionStatus.value.length > 0}
                        InputLabelProps={{
                            classes: { root: classes.inputLabel },
                        }}
                        variant="filled"
                        label="Admission Status:"
                        suggestions={admissionStatuses}
                        disableUnderline={true}
                        onChange={(e) => updateField({ Field: 'admissionStatus', Value: e.value })}
                        value={providerInfoForm.admissionStatus.value}
                    />
                </Grid>
                <Grid item xs={6} sm={6}>
                    <Datepicker
                        id="DOS"
                        ref={(el) => (refs.DOS = el)}
                        error={!providerInfoForm.DOS.valid}
                        errorMessage={'Please provide a Date of Service'}
                        // validator={() => !!providerInfoForm.DOS.value}
                        type="date"
                        InputLabelProps={{
                            classes: { root: classes.inputLabel },
                        }}
                        inputVariant="filled"
                        disableUnderline={true}
                        onChange={(val) => {
                            if (new Date(eventInfoForm.procedureStartTime.value).getTime() === new Date(eventInfoForm.procedureEndTime.value).getTime()) {
                                updateEventInfoField({
                                    Field: 'procedureStartTime',
                                    Value: val,
                                    ExpectedType: 'object',
                                });
                                updateEventInfoField({
                                    Field: 'procedureEndTime',
                                    Value: val,
                                    ExpectedType: 'object',
                                });
                            }
                            if (new Date(eventInfoForm.anesthesiaStartTime.value).getTime() === new Date(eventInfoForm.anesthesiaEndTime.value).getTime()) {
                                updateEventInfoField({
                                    Field: 'anesthesiaStartTime',
                                    Value: val,
                                    ExpectedType: 'object',
                                });
                                updateEventInfoField({
                                    Field: 'anesthesiaEndTime',
                                    Value: val,
                                    ExpectedType: 'object',
                                });
                            }
                            updateField({ Field: 'DOS', Value: val, ExpectedType: 'object' });
                        }}
                        value={providerInfoForm.DOS.value}
                        label="DOS:"
                    />
                </Grid>
                <Grid item xs={6} sm={6}>
                    <TypeSelect
                        required
                        id="procedureStatus"
                        ref={(el) => (refs.procedureStatus = el)}
                        error={!providerInfoForm.procedureStatus.valid}
                        errorMessage={'Please provide a Procedure Status'}
                        validator={() => providerInfoForm.procedureStatus.value.length > 0}
                        InputLabelProps={{
                            classes: { root: classes.inputLabel, asterisk: classes.asterisk },
                        }}
                        variant="filled"
                        label="Procedure Status:"
                        suggestions={procedureStatuses}
                        disableUnderline={true}
                        onChange={(e) => updateField({ Field: 'procedureStatus', Value: e.value })}
                        value={providerInfoForm.procedureStatus.value}
                    />
                </Grid>
                <Grid item xs={6} sm={6} style={{ display: 'flex', alignItems: 'center' }}>
                    <FormControlLabel
                        classes={{ label: classes.inputLabel }}
                        control={
                            <Checkbox
                                checked={providerInfoForm.saveProviderInfo.value}
                                onChange={(e) => updateField({ Field: 'saveProviderInfo', Value: e.target.checked })}
                            />
                        }
                        label="Save Provider Info"
                    />
                </Grid>
            </Grid>
        </Page>
    );
};

const mapStateToProps = ({ providerInfoForm, eventInfoForm }) => ({ providerInfoForm, eventInfoForm });
const actionCreators = { updateField, updateEventInfoField };

export default connect(mapStateToProps, actionCreators)(withStyles(styles)(ProviderInfo));
