const styles = theme => ({
    title: {
        fontSize: 14,
        fontWeight: 700,
        fontfamily: 'Roboto',
        padding: 16,
    },
    inputLabel: {
        fontSize: 14,
    },
    content: {
        paddingLeft: 8,
        paddingRight: 8,
        paddingBottom: 0,
        paddingTop: 0
    }
});

export default styles;