export default {
    palette: {
        primary: {
            main: '#31365e',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#626689',
            contrastText: '#ffffff',
        },
        error: {
            main: '#e50000',
            contrastText: '#ffffff',
        },
    },
    shadows: ['none'],
    overrides: {
        MuiFilledInput: {
            root: {
                borderRadius: 0,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                backgroundColor: '#e8e8e8 !important',
            },
        },
        MuiButton: {
            outlinedPrimary: {
                borderWidth: 2,
            },
            text: {
                fontSize: 15,
                fontFamily: 'Roboto',
                fontWeight: 700,
                textTransform: 'none',
            },
        },
    },
};
