const styles = theme => ({
    root: {
        marginBottom: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    eventHeader: {
        position: 'relative',
        backgroundColor: '#636b9e',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        minHeight: 40,
    },
    eventText: {
        fontFamily: 'Roboto',
        fontSize: 16,
        fontWeight: '800'
    },
    eventRemoveIcon: {
        position: 'absolute',
        right: 5
    },
    eventSubText: {
        fontFamily: 'Roboto',
        fontSize: 12,
        fontWeight: '500'
    },
    eventAttributes: {
        paddingTop: 10,
        paddingBottom: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        backgroundColor: '#ededed'
    }
});

export default styles;