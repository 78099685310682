import {
    secondaryColor, primaryColor
} from '../../../../styles'

const styles = theme => ({
    title: {
        fontSize: 14,
        fontWeight: 700,
        fontfamily: 'Roboto',
        marginBottom: 6,
    },
    inputLabel: {
        fontSize: 14,
    },
    root: {
        
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    form: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    stepText: {
        color: '#b8b8b8',
        fontFamily: 'Roboto',
        fontSize: 15,
        fontWeight: 400,
        marginTop: 4
    },
    noEvents: {
        padding: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    noEventsText: {
        color: secondaryColor,
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 400
    },
    addNewEventButton: {
        color: primaryColor,
        fontSize: 15,
        fontFamily: "Roboto",
        fontWeight: 700,
        backgroundColor: "#ededed",
        "&:hover": {
            backgroundColor: "#cfcfcf"
        }
    },
    totalTimeSection: {
        marginTop: 3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    timeTitle: {
        fontFamily: "Roboto",
        fontSize: "10px",
        fontWeight: 400
    },
    totalTime: {
        marginLeft: 4,
        marginBottom: 1,
        fontFamily: "Roboto",
        fontSize: "10px",
        fontWeight: 400,
        color: secondaryColor
    }
});

export default styles;