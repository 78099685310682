import { API } from 'aws-amplify';
import cloneDeep from 'lodash.clonedeep';
import {
    GET_FREQUENT_USED_TERMS_ERROR,
    GET_FREQUENT_USED_TERMS_SUCCESS,
    SET_FREQUENT_USED_TERMS,
    INIT
} from '../reducers/frequentlyUsedTerms'


export const get = () => async (dispatch, getState) => {
    let path = `/icd10/frequent`
    try {
        let response = await API.get('ASDAPatientOutcomeAPI', path)
        dispatch({
            type: GET_FREQUENT_USED_TERMS_SUCCESS,
            payload: response.payload
        })
    }
    catch(error) {
        console.log(error);
        dispatch({
            type: GET_FREQUENT_USED_TERMS_ERROR,
        })
        throw error;
    }
}

export const set = form => {
    if (form) {
      return {
        type: SET_FREQUENT_USED_TERMS,
        payload: form
      }
    }
    // Empty user in store if no user is sent
    return {
      type: SET_FREQUENT_USED_TERMS,
      payload: cloneDeep(INIT)
    }
}