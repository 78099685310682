import awsConfigDev from './aws-config.dev';
import awsConfigStage from './aws-config.staging';
import awsConfigProd from './aws-config.prod';

let awsConfig;
// load the correct aws config options
switch(process.env.REACT_APP_ENV) {
  case 'prod':
    awsConfig = awsConfigProd;
  break
  case 'staging':
    awsConfig = awsConfigStage;
  break
  case 'dev':
    awsConfig = awsConfigDev
  break
  default:
    // awsConfig = awsConfigStage;
    awsConfig = awsConfigDev
}
export {
    awsConfig
}