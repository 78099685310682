export const toolbarHeight = 60
export const primaryColor = "#31365e"
export const secondaryColor = "#626689"

export const searchIconColor = "#34a9d0"

export const EventColors = {
    Respiratory: "#5DC29D",
    Cardiovascular: "#8A3F3F",
    Medication: "#7A8FB1",
    "Patient Safety": "#C25D5D",
    Postoperative: "#A45B73",
    Other: "#434567",
}