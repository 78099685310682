import { toolbarHeight } from "../../styles";

const styles = theme => ({
    root: {
        flexGrow: 1,
        height: '100%',
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        width: '100%',
    },
    appBar: {
        position: 'absolute',
        backgroundColor: 'white',
        [theme.breakpoints.up('md')]: {
            width: `calc(100%)`,
        },
        borderBottom: "1px solid #3eb0d1"
    },
    toolbar: {
        ...theme.mixins.toolbar,
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        height: toolbarHeight,
        // flexDirection: 'column'
    },
    toolbarLogo: {
        height: '36px',
        cursor: 'pointer'
    },
    logoutIcon: {
        position: "absolute",
        right: "10px",
        height: '100%'
    },
    searchIcon: {
        position: "absolute",
        left: 10,
    },
    content: {
        flexGrow: 1,
        overflow: 'scroll',
        backgroundColor: 'white'
    },
});

export default styles;