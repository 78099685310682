const styles = theme => ({
    root: {
        flexGrow: 1,
        height: 250,
    },
    input: {
        display: 'flex',
    },
    menuItem: {
        whiteSpace: 'pre-wrap',
    },
    valueContainer: {
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        whiteSpace: 'nowrap',
        overflow: 'scroll',
    },
    noOptionsMessage: {
        padding: theme.spacing(1, 2),
    },
    singleValue: {
        fontSize: 16,
    },
    paper: {
        position: 'absolute',
        zIndex: 999,
        marginTop: 0,
        left: 0,
        right: 0,
        // overflow: 'scroll',
    },
    chevron: { // Just hide the chevron icon for now
        marginBottom: 16,
        // flexShrink: 1,
        width: 0, height: 0,
        color: 'transparent'
    }
});

export default styles;