import cloneDeep from 'lodash.clonedeep'
import { 
    INIT,
    SET_PATIENT_INFO_FORM,
    PATIENT_INFO_FORM_UPDATE_FIELD,
    RESET_PATIENT_INFO_FORM
} from '../reducers/patientInfoForm';

export const reset = () => {
  return {
    type: RESET_PATIENT_INFO_FORM,
    payload: INIT
  }
}

export const set = form => {
  if (form) {
    return {
      type: SET_PATIENT_INFO_FORM,
      payload: form
    }
  }
  // Empty user in store if no user is sent
  return {
    type: SET_PATIENT_INFO_FORM,
    payload: INIT
  }
}

export const updateField = ({ Field, Value, Valid=true, ExpectedType }) => (dispatch, getState) => {
  const { patientInfoForm } = cloneDeep(getState());

  // Check and make sure the type of field is the same as the INIT
  if(Value !== null && Value !== undefined) {
    if(typeof INIT[Field].value === typeof Value || ExpectedType === typeof Value) {
      patientInfoForm[Field].value = Value
    }
  }
  patientInfoForm[Field].valid = Valid;

  dispatch({
    type: PATIENT_INFO_FORM_UPDATE_FIELD,
    payload: patientInfoForm
  })

  return patientInfoForm;
  
}
