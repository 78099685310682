const eventTypes = {
    Respiratory: [
        "Airway obstruction",
        "Airway trauma",
        "Aspiration",
        "Difficult intubation",
        "Difficult mask ventilation",
        "Hypoxemia",
        "Pneumonia",
        "Pneumothorax",
        "Pulmonary edema",
        "Unplanned endobronchial intubation",
        "Unplanned esophageal intubation",
        "Unplanned extubation",
        "Unplanned reintubation"
    ],
    Cardiovascular: [
        "Arrhythmia",
        "Bradycardia",
        "Cardiac arrest",
        "Myocardial ischemia",
        "Myocardial infarction",
        "Hypertension",
        "Hypotension",
        "Cerebrovascular accident",
        "Coagulopathy",
        "Deep vein thrombosis"
    ],
    Medication: [
        "Adverse drug reaction",
        "Anaphylaxis",
        "Local anesthetic toxicity",
        "Medication error",
        "Malignant hyperthermia",
        "Prolonged neuromuscular blockade"
    ],
    "Patient Safety": [
        "Burn injury",
        "Eye injury",
        "Skin/mucosa injury",
        "Positioning injury",
        "IV infiltration",
        "OR fire",
        "Equipment failure/malfunction",
        "Fall",
        "Wrong patient"
    ],
    Postoperative: [
        "Delayed emergence",
        "Emergence delirium",
        "Postoperative delirium",
        "Postoperative cognitive dysfunction",
        "Postoperative nausea/vomiting",
        "Postdischarge nausea/vomiting",
        "Inadequate pain control"
    ],
    Other: [
        "Awareness",
        "Seizure",
        "Hypoglycemia",
        "Unplanned conversion to GA",
        "Unplanned hospital admission",
        "Coma",
        "Death"
    ]
}

const severities = [
    "No harm",
    "Mild harm",
    "Moderate harm",
    "Severe harm",
    "Death"
]

const timeFrames = [
    "Preoperative",
    "Intraoperative",
    "Postoperative (before discharge)",
    "Within 24 hrs postop",
    "Within 24-48 hrs postop",
]


module.exports = {
    eventTypes,
    severities,
    timeFrames
}