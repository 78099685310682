import {
    primaryColor
} from '../../../../styles'

const styles = theme => ({
    root: {
        zIndex: 999,
        backgroundColor: '#31365e',
        position: 'sticky',
        bottom: 0,
        width: '100%',
        padding: '24px 24px',
        boxSizing: 'border-box',
        overflow: 'scroll',
        color: 'white',
        [theme.breakpoints.down('sm')]: {
            maxHeight: 250,
            padding: '12px 12px'
        }
    },
    title: {
        fontSize: 14,
        fontWeight: 700,
        fontfamily: 'Roboto',
        marginBottom: 6,
    },
    inputLabel: {
        fontSize: 12,
        color: 'white',
        textDecoration: 'underline',
        transform: 'none',
        left: 10,
        top: 11,
    },
    inputRoot: {
        color: 'white',
        backgroundColor: '#1d2139 !important',
    },
    input: {
        padding: '10px 12px 10px',
        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
            padding: '10px 4px 10px 4px'
        },
        backgroundColor: '#1d2139',
        color: 'white',
        textAlign: 'center'
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    form: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    stepText: {
        color: '#b8b8b8',
        fontFamily: 'Roboto',
        fontSize: 15,
        fontWeight: 400,
        marginTop: 4
    },
    addNewEventButton: {
        color: primaryColor,
        fontSize: 15,
        fontFamily: "Roboto",
        fontWeight: 700,
        backgroundColor: "#ededed",
        "&:hover": {
            backgroundColor: "#cfcfcf"
        }
    },
    totalTimeSection: {
        marginTop: 3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    timeTitle: {
        fontFamily: "Roboto",
        fontSize: "10px",
        fontWeight: 400
    },
    totalTime: {
        marginLeft: 4,
        marginBottom: 1,
        fontFamily: "Roboto",
        fontSize: "10px",
        fontWeight: 400,
    },
    discharge: {
        left: '50%',
        transform: 'translateX(-50%)'
    },
    dischargeTitle: {
        zIndex: 1,
        position: 'absolute',
        left: 35,
        bottom: 32,
        [theme.breakpoints.down('sm')]: {
            left: 20,
            bottom: 17,
        }
    }
});

export default styles;