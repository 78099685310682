import React, { useEffect } from 'react';
import { connect }    from 'react-redux'
import { updateField } from '../../../../redux/actions/patientInfoForm'
import { get as getFrequentTerms } from '../../../../redux/actions/frequentlyUsedTerms';
import { withStyles } from '@material-ui/core/styles';
import { 
  Grid, 
  Typography,
  IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'
import Input from '../../../../components/Input';
import Datepicker from '../../../../components/Input/Datepicker';
import TypeSelect from '../../../../components/Input/TypeSelect';
import Page  from '../../../../components/Page';

import styles from './styles';

import { 
  anesthesiaTypes,
  ASAClasses,
  CPTCodesAnesthesia,
  CPTCodesSurgical,
  genders,
  PaymentMethods
} from './selects'
import { API } from 'aws-amplify';

const DEBOUNCE_TIMER = 500;

const PatientInfo = (props) => {
  const { classes, patientInfoForm, updateField, frequentlyUsedTerms, getFrequentTerms, refs } = props;

  useEffect(() => {
    async function _getTerms() {
      try {
        await getFrequentTerms();
      }
      catch(error) { console.log("Error getting frequent terms", error)}
    }
    _getTerms();
  }, []);

  const ICDTimer = React.useRef(null);
  function handleIcd10Search(inputValue) {
    return new Promise((resolve, reject) => {
      clearTimeout(ICDTimer.current);
      ICDTimer.current = setTimeout( async () => {
        try {
          let response = await API.get('ASDAPatientOutcomeAPI', `/icd10?search=${inputValue}&page=1&perPage=50`);
          let items = response.result.rows.map(row => ({value: row, label: row.title}));
          let relatedFrequentlyUsedTerms = handleDefaultOptions(inputValue);
          resolve([...relatedFrequentlyUsedTerms, ...items]);
        }
        catch(error) {
          reject(error);
          console.log(error);
        }
      }, DEBOUNCE_TIMER);
    })
  }

  function handleDefaultOptions(inputValue = "") {
    return Object.keys(frequentlyUsedTerms).map(key => {
      let term = frequentlyUsedTerms[key].term
      if(term.title.toLowerCase().includes(inputValue.toLowerCase())) {
        return { value: term, label: term.title };
      }
      return null;
    }).filter(value => !!value);
  }

  function calculateBMI(height, kilograms) {
    if(kilograms && height) {
      height = (parseFloat(height)*2.54) / 100
      let BMI = parseFloat(kilograms) / (height * height);
      updateField({Field: 'bmi', Value: `${BMI.toFixed(3)}`})
    }
  }

  function calculateBirthday(e) {
    var ageDifMs = Date.now() - new Date(e).getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    let age = Math.abs(ageDate.getUTCFullYear() - 1970);
    updateField({ Field: 'age', Value: `${age}` })
  }

  return (
    <Page classes={{root: classes.root}}>
      <Grid container spacing={1}>
        <Grid item xs={6} sm={6}>
          <Input
            id='patientId'
            ref={el => refs.patientId = el}
            error={!patientInfoForm.patientId.valid} 
            // errorMessage={"Please provide a Patient Id"}
            // validator={() => patientInfoForm.patientId.value.length > 0}
            InputLabelProps={{
              classes: { root: classes.inputLabel }
            }}
            variant="filled"
            disableUnderline={true}
            onChange={(e) => updateField({ Field: 'patientId', Value: e.target.value} )}
            value={patientInfoForm.patientId.value}
            label="Pt ID:"
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <Datepicker
            id='dateOfBirth'
            disableFuture={true}
            ref={el => refs.dateOfBirth = el}
            error={!patientInfoForm.dateOfBirth.valid} 
            // errorMessage={"Please provide a Patient DOB"}
            // validator={() => !!patientInfoForm.dateOfBirth.value}
            type="date"
            InputLabelProps={{
              classes: { root: classes.inputLabel }
            }}
            inputVariant='filled'
            disableUnderline={true}
            onChange={(e) => {
              updateField({ Field: 'dateOfBirth', Value: e, ExpectedType: 'object' });
              calculateBirthday(e);
            }}
            value={patientInfoForm.dateOfBirth.value}
            label="DOB:"
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <Input
            required
            id='age'
            ref={el => refs.age = el}
            error={!patientInfoForm.age.valid} 
            errorMessage={"Please provide an age"}
            validator={() => !!patientInfoForm.age.value }
            type="number"
            InputLabelProps={{
              classes: { root: classes.inputLabel, asterisk: classes.asterisk }
            }}
            variant="filled"
            disableUnderline={true}
            onChange={(e) => { 
              updateField({ Field: 'age', Value: e.target.value });
              updateField({ Field: 'dateOfBirth', Value: "" });
            }}
            value={patientInfoForm.age.value}
            label="Age:"
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <TypeSelect
            required
            id='gender'
            ref={el => refs.gender = el}
            error={!patientInfoForm.gender.valid} 
            errorMessage={"Please provide a Gender"}
            validator={() => patientInfoForm.gender.value.length > 0}
            InputLabelProps={{
                classes: { root: classes.inputLabel, asterisk: classes.asterisk }
            }}
            variant='filled'
            label="Gender:"
            suggestions={genders}    
            disableUnderline={true}
            onChange={(e) => updateField({ Field: 'gender', Value: e.value })}
            value={patientInfoForm.gender.value}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            id='bmi'
            ref={el => refs.bmi = el}
            error={!patientInfoForm.bmi.valid} 
            // errorMessage={"Please provide a BMI"}
            // validator={() => !!patientInfoForm.bmi.value }
            type="number"
            disabled={true}
            InputLabelProps={{
              classes: { root: classes.inputLabel }
            }}
            variant="filled"
            disableUnderline={true}
            onChange={(e) => updateField({ Field: 'bmi', Value: e.target.value })}
            value={patientInfoForm.bmi.value}
            label="BMI:"
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <Input
            id='height'
            ref={el => refs.height = el}
            error={!patientInfoForm.height.valid} 
            // errorMessage={"Please provide a weight in lbs"}
            // validator={() => !!patientInfoForm.height.value}
            type="number"
            InputLabelProps={{
              classes: { root: classes.inputLabel }
            }}
            variant="filled"
            disableUnderline={true}
            onChange={(e) => {
              updateField({ Field: 'height', Value: e.target.value })
              calculateBMI(e.target.value, patientInfoForm.kilograms.value);
            }}
            value={patientInfoForm.height.value}
            errortext={patientInfoForm.height.valid ? '' : 'Please enter a valid height in inches'} 
            label="Height (in):"
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <Input
            id='kilograms'
            ref={el => refs.kilograms = el}
            error={!patientInfoForm.kilograms.valid} 
            // errorMessage={"Please provide a weight in kilograms"}
            // validator={() => !!patientInfoForm.kilograms.value}
            type="number"
            InputLabelProps={{
              classes: { root: classes.inputLabel }
            }}
            variant="filled"
            disableUnderline={true}
            onChange={ (e) => {
              updateField({ Field: 'kilograms', Value: e.target.value })
              calculateBMI(patientInfoForm.height.value, e.target.value);
            }}
            value={patientInfoForm.kilograms.value}
            label="kgs:"
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <TypeSelect
            required
            id='asa'
            ref={el => refs.asa = el}
            error={!patientInfoForm.asa.valid} 
            errorMessage={"Please provide an ASA"}
            validator={() => patientInfoForm.asa.value.length > 0}
            InputLabelProps={{
                classes: { root: classes.inputLabel, asterisk: classes.asterisk }
            }}
            variant='filled'
            label="ASA:"
            suggestions={ASAClasses}    
            disableUnderline={true}
            onChange={(e) => updateField({ Field: 'asa', Value: e.value })}
            value={patientInfoForm.asa.value}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <TypeSelect
            required
            id='anesthesiaType'
            ref={el => refs.anesthesiaType = el}
            error={!patientInfoForm.anesthesiaType.valid} 
            errorMessage={"Please provide a type of Anesthesia"}
            validator={() => patientInfoForm.anesthesiaType.value.length > 0}
            InputLabelProps={{
                classes: { root: classes.inputLabel, asterisk: classes.asterisk }
            }}
            variant='filled'
            label="Anesthesia Type:" 
            suggestions={anesthesiaTypes}    
            disableUnderline={true}
            onChange={(e) => updateField({ Field: 'anesthesiaType', Value: e.value })}
            value={patientInfoForm.anesthesiaType.value}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TypeSelect
            required
            id='paymentMethod'
            ref={el => refs.paymentMethod = el}
            error={!patientInfoForm.paymentMethod.valid} 
            errorMessage={"Please provide a payment method"}
            validator={() => patientInfoForm.paymentMethod.value.length > 0}
            InputLabelProps={{
                classes: { root: classes.inputLabel, asterisk: classes.asterisk }
            }}
            variant='filled'
            label="Payment Method:"
            suggestions={PaymentMethods}    
            disableUnderline={true}
            onChange={(e) => updateField({ Field: 'paymentMethod', Value: e.value })}
            value={patientInfoForm.paymentMethod.value}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TypeSelect
            id='icd10'
            ref={el => refs.icd10 = el}
            error={!patientInfoForm.icd10.valid} 
            errorMessage={"Please provide atleast one ICD10 Code"}
            // validator={() => patientInfoForm.icd10.value.length > 0}
            async={true}
            InputLabelProps={{
                classes: { root: classes.inputLabel }
            }}
            variant='filled'
            label="ICD10:"
            defaultOptions={handleDefaultOptions()}
            loadOptions={handleIcd10Search}
            disableUnderline={true}
            onChange={(e) => {
              // Check to make sure no duplicates
              if(patientInfoForm.icd10.value.includes(e.value)) return;
              // If no duplicates updateField
              updateField({ 
                Field: 'icd10', 
                Value: [e.value, ...patientInfoForm.icd10.value] 
              })
            }}
            value={''}
          />
        </Grid>
        {
          patientInfoForm.icd10.value.map((code,index) => {
            return (
              <Grid key={`icdcode-${index}`} item xs={12} sm={12}>
                <div className={classes.selectedCPT}>
                  <div className={classes.cptText}>
                    <Typography className={classes.cptCode}> {code.code} </Typography>
                    <Typography className={classes.cptTitle}> {code.title} </Typography>
                  </div>
                  <IconButton
                      aria-label="remove-icd10"
                      onClick={() => updateField({Field: 'icd10', Value: patientInfoForm.icd10.value.filter((value) => value.code !== code.code)})}
                      className={classes.cptRemoveIcon}>
                      <CloseIcon style={{color: 'white'}}/>
                  </IconButton>
                </div>
              </Grid>
            ) 
          })
        }
        <Grid item xs={12} sm={12}>
          <TypeSelect
            id='CPTAnesthesiaCodes'
            ref={el => refs.CPTAnesthesiaCodes = el}
            error={!patientInfoForm.CPTAnesthesiaCodes.valid} 
            errorMessage={"Please provide atleast 1 Anesthesia Code"}
            // validator={() => patientInfoForm.CPTAnesthesiaCodes.value.length > 0}
            InputLabelProps={{
                classes: { root: classes.inputLabel }
            }}
            variant='filled'
            label="CPT Anesthesia Code(s):"
            suggestions={CPTCodesAnesthesia.filter(value => !patientInfoForm.CPTAnesthesiaCodes.value.includes(value))}
            disableUnderline={true}
            onChange={(e) => {
              // Check to make sure no duplicates
              if(patientInfoForm.CPTAnesthesiaCodes.value.includes(e.value)) return;
              // If no duplicates updateField
              updateField({ 
                Field: 'CPTAnesthesiaCodes', 
                Value: [e.value, ...patientInfoForm.CPTAnesthesiaCodes.value] 
              })
            }}
            value={""}
          />
        </Grid>
        {
          patientInfoForm.CPTAnesthesiaCodes.value.map((code,index) => {
            var codePrefix = code.split(' ').shift();
            var title = code.replace(codePrefix, '');
            return (
              <Grid key={`code-${index}`} item xs={12} sm={12}>
                <div className={classes.selectedCPT}>
                  <div className={classes.cptText}>
                    <Typography className={classes.cptCode}> {codePrefix} </Typography>
                    <Typography className={classes.cptTitle}> {title} </Typography>
                  </div>
                  <IconButton
                      aria-label="remove-cpt"
                      onClick={() => updateField({Field: 'CPTAnesthesiaCodes', Value: patientInfoForm.CPTAnesthesiaCodes.value.filter((value) => value !== code)})}
                      className={classes.cptRemoveIcon}>
                      <CloseIcon style={{color: 'white'}}/>
                  </IconButton>
                </div>
              </Grid>
            ) 
          })
        }
        <Grid item xs={12} sm={12}>
          <TypeSelect
            id='CPTSurgeryCodes'
            ref={el => refs.CPTSurgeryCodes = el}
            error={!patientInfoForm.CPTSurgeryCodes.valid} 
            errorMessage={"Please provide atleast 1 Surgery Code"}
            // validator={() => patientInfoForm.CPTSurgeryCodes.value.length > 0}
            InputLabelProps={{
                classes: { root: classes.inputLabel }
            }}
            variant='filled'
            label="CPT Surgery Code(s):"
            suggestions={CPTCodesSurgical.filter(value => !patientInfoForm.CPTSurgeryCodes.value.includes(value))}    
            disableUnderline={true}
            onChange={(e) => {
              // Check for duplicates
              if (patientInfoForm.CPTSurgeryCodes.value.includes(e.value)) return
              // If not duplicates update
              updateField({ 
                Field: 'CPTSurgeryCodes', 
                Value: [e.value, ...patientInfoForm.CPTSurgeryCodes.value]
              })
            }}
            value={""}
          />
        </Grid>
        {
          patientInfoForm.CPTSurgeryCodes.value.map((code,index) => {
            var codePrefix = code.split(' ').shift();
            var title = code.replace(codePrefix, '');
            return (
              <Grid key={`code-${index}`} item xs={12} sm={12}>
                <div className={classes.selectedCPT}>
                  <div className={classes.cptText}>
                    <Typography className={classes.cptCode}> {codePrefix} </Typography>
                    <Typography className={classes.cptTitle}> {title} </Typography>
                  </div>
                  <IconButton
                      aria-label="remove-cpt"
                      onClick={() => updateField({Field: 'CPTSurgeryCodes', Value: patientInfoForm.CPTSurgeryCodes.value.filter((value) => value !== code)})}
                      className={classes.cptRemoveIcon}>
                      <CloseIcon style={{color: 'white'}}/>
                  </IconButton>
                </div>
              </Grid>
            ) 
          })
        }
      </Grid>
    </Page>
  );
}

const mapStateToProps = ({ patientInfoForm, frequentlyUsedTerms }) => ({ patientInfoForm, frequentlyUsedTerms })
const actionCreators = { getFrequentTerms, updateField };

export default connect(mapStateToProps, actionCreators)(withStyles(styles)(PatientInfo));