import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { updateField } from '../../../../redux/actions/eventInfoForm';
import { setSnackbar } from '../../../../redux/actions/snackbar';
import ValidateDates from '../../../../modules/validate-form-dates';

import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

import Datepicker from '../../../../components/Input/Datepicker';

import styles from './styles';
import moment from 'moment';
import clsx from 'classnames';

const EventInfo = (props) => {
	const { classes, eventInfoForm, patientInfoForm, updateField, refs, errorMsgQueue } = props;

	function getTimeBetweenTwoDates(startDate, endDate) {
		let _startDate = moment(startDate);
		let _endDate = moment(endDate);
		if (_startDate._isValid && _endDate._isValid) {
			let ms = moment(_endDate).diff(_startDate);
			var d = moment.duration(ms);
			var s = Math.floor(d.asHours()) + moment.utc(ms).format(':mm:ss');

			return `${s}`;
		}

		return '00:00:00';
	}

	return (
		<div className={classes.root}>
			<Grid container spacing={1}>
				<Grid item xs={6}>
					<Typography className={classes.title} variant={'h6'}>
						Anesthesia*
					</Typography>
					<Datepicker
						id='anesthesiaStartTime'
						borderError
						ref={(el) => (refs.anesthesiaStartTime = el)}
						error={!eventInfoForm.anesthesiaStartTime?.valid}
						validator={() => {
							if (
								!!patientInfoForm.dateOfBirth?.value &&
								new Date(eventInfoForm.anesthesiaStartTime?.value) <= new Date(patientInfoForm.dateOfBirth?.value)
							) {
								errorMsgQueue.push('Birth date must precede anesthesia start time');
								return false;
							}
							if (
								!ValidateDates(eventInfoForm.anesthesiaStartTime?.value, eventInfoForm.anesthesiaEndTime?.value, 'start')
							) {
								errorMsgQueue.push('Anesthesia start time must precede anesthesia end time');
								return false;
							}
							if (
								new Date(eventInfoForm.anesthesiaStartTime?.value) >= new Date(eventInfoForm.procedureStartTime?.value)
							) {
								errorMsgQueue.push('Anesthesia start time must precede procedure start time');
								return false;
							}
							return true;
						}}
						marginBottom={4}
						onError={(e) => updateField({ Field: 'anesthesiaStartTime', Valid: false })}
						InputProps={{
							classes: { root: classes.inputRoot, input: classes.input },
						}}
						InputLabelProps={{
							classes: { root: classes.inputLabel },
							focused: false,
							shrink: false,
						}}
						inputVariant='filled'
						disableUnderline={true}
						onChange={(e) => updateField({ Field: 'anesthesiaStartTime', Value: e, ExpectedType: 'object' })}
						value={eventInfoForm.anesthesiaStartTime?.value}
						format='MM/dd - hh:mm a'
					/>
					<Datepicker
						id='anesthesiaEndTime'
						borderError
						ref={(el) => (refs.anesthesiaEndTime = el)}
						error={!eventInfoForm.anesthesiaEndTime?.valid}
						validator={() => {
							if (new Date(eventInfoForm.anesthesiaEndTime?.value) <= new Date(eventInfoForm.procedureEndTime?.value)) {
								errorMsgQueue.push('Procedure end time must precede anesthesia end time');
								return false;
							}
							return true;
						}}
						inputVariant='filled'
						marginBottom={4}
						onError={(e) => updateField({ Field: 'anesthesiaEndTime', Valid: false })}
						// label={eventInfoForm.anesthesiaEndTime?.value === '' ? 'Tap to enter' : ''}
						InputProps={{
							classes: { root: classes.inputRoot, input: classes.input },
						}}
						InputLabelProps={{
							classes: { root: classes.inputLabel },
							focused: false,
							shrink: false,
						}}
						minDate={moment(eventInfoForm.anesthesiaStartTime?.value)}
						variant='filled'
						disableUnderline={true}
						onChange={(e) => updateField({ Field: 'anesthesiaEndTime', Value: e, ExpectedType: 'object' })}
						value={eventInfoForm.anesthesiaEndTime?.value}
						format='MM/dd - hh:mm a'
					/>
				</Grid>
				<Grid item xs={6}>
					<Typography className={classes.title} variant={'h6'}>
						Procedure*
					</Typography>
					<Datepicker
						id='procedureStartTime'
						borderError
						ref={(el) => (refs.procedureStartTime = el)}
						error={!eventInfoForm.procedureStartTime?.valid}
						validator={() => {
							if (
								!ValidateDates(eventInfoForm.procedureStartTime?.value, eventInfoForm.procedureEndTime?.value, 'start')
							) {
								errorMsgQueue.push('Procedure start time must precede procedure end time');
								return false;
							}
							return true;
						}}
						marginBottom={4}
						onError={(e) => updateField({ Field: 'procedureStartTime', Valid: false })}
						// label={eventInfoForm.procedureStartTime?.value === '' ? 'Tap to enter' : ''}
						InputLabelProps={{
							classes: { root: classes.inputLabel },
							focused: false,
							shrink: false,
						}}
						InputProps={{
							classes: { root: classes.inputRoot, input: classes.input },
						}}
						inputVariant='filled'
						disableUnderline={true}
						onChange={(e) => updateField({ Field: 'procedureStartTime', Value: e, ExpectedType: 'object' })}
						value={eventInfoForm.procedureStartTime?.value}
						format='MM/dd - hh:mm a'
					/>
					<Datepicker
						id='procedureEndTime'
						borderError
						ref={(el) => (refs.procedureEndTime = el)}
						error={!eventInfoForm.procedureEndTime?.valid}
						marginBottom={4}
						onError={(e) => updateField({ Field: 'procedureEndTime', Valid: false })}
						// label={eventInfoForm.procedureEndTime?.value === '' ? 'Tap to enter' : ''}
						InputLabelProps={{
							classes: { root: classes.inputLabel },
							focused: false,
							shrink: false,
						}}
						InputProps={{
							classes: { root: classes.inputRoot, input: classes.input },
						}}
						minDate={moment(eventInfoForm.procedureStartTime?.value)}
						inputVariant='filled'
						disableUnderline={true}
						onChange={(e) => updateField({ Field: 'procedureEndTime', Value: e, ExpectedType: 'object' })}
						value={eventInfoForm.procedureEndTime?.value}
						errortext={eventInfoForm.procedureEndTime?.valid ? '' : 'Please enter a valid End Time'}
						format='MM/dd - hh:mm a'
					/>
				</Grid>

				<Grid item xs={12}>
					<Typography className={clsx(classes.title, classes.dischargeTitle)} variant={'h6'}>
						Discharge
					</Typography>
					<Datepicker
						id='timeReadyDischarge'
						borderError
						ref={(el) => (refs.timeReadyDischarge = el)}
						error={!eventInfoForm.timeReadyDischarge?.valid}
						validator={() => {
							if (
								!!eventInfoForm.timeReadyDischarge?.value &&
								new Date(eventInfoForm.timeReadyDischarge?.value) <= new Date(eventInfoForm.anesthesiaEndTime?.value)
							) {
								errorMsgQueue.push('Anesthesia end time must preceded discharge date');
								return false;
							}
							return true;
						}}
						marginBottom={4}
						// label={eventInfoForm.timeReadyDischarge?.value === '' ? 'Tap to enter' : ''}
						InputLabelProps={{
							classes: { root: clsx(classes.inputLabel, classes.discharge) },
							focused: false,
							shrink: false,
						}}
						InputProps={{
							classes: { root: classes.inputRoot, input: classes.input },
						}}
						inputVariant='filled'
						disableUnderline={true}
						onChange={(e) => updateField({ Field: 'timeReadyDischarge', Value: e, ExpectedType: 'object' })}
						value={eventInfoForm.timeReadyDischarge?.value}
						format='MM/dd - hh:mm a'
					/>
				</Grid>
			</Grid>
		</div>
	);
};

const mapStateToProps = ({ eventInfoForm, patientInfoForm }) => ({ eventInfoForm, patientInfoForm });
const actionCreators = { updateField, setSnackbar };

export default connect(mapStateToProps, actionCreators)(withStyles(styles)(EventInfo));
