import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

// Material UI Core
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SearchIcon from '@material-ui/icons/Search';
import ASDALogo from '../../assets/ASDA_Logo.png';

// Redux
import { signOut } from '../../redux/actions/user';
import { toggleSearchForm, setSearchFormId } from '../../redux/actions/searchForm';
import styles from './styles';

import { searchIconColor } from '../../styles'

const Navigation = (props) => {
	const { classes, signOut, toggleSearchForm, setSearchFormId, searchForm, location } = props;

	async function handleLogout() {
		await signOut();
	}

	function handleLogoClick() {
		props.history.replace('/');
	}

	function handleSearchClick() {
		toggleSearchForm(!searchForm.open);
		setSearchFormId('');
	}

	return (
		<div className={classes.root}>
			<AppBar className={classes.appBar}>
				<Toolbar className={classes.toolbar}>
					{location.pathname === '/' && (
						<IconButton
							style={{ color: searchForm.open ? searchIconColor : 'initial' }}
							aria-label='Search'
							className={classes.searchIcon}
							onClick={handleSearchClick}
						>
							<SearchIcon />
						</IconButton>
					)}
					<img
						className={classes.toolbarLogo}
						onClick={handleLogoClick}
						src={ASDALogo}
						alt={'Logo'}
					/>
					<IconButton aria-label='Logout' onClick={handleLogout} className={classes.logoutIcon}>
						<ExitToAppIcon />
					</IconButton>
				</Toolbar>
			</AppBar>

			<main className={classes.content}>
				<div className={classes.toolbar} />
				{props.children}
			</main>
		</div>
	);
};

const mapStateToProps = (state) => ({ searchForm: state.searchForm });
const mapDispatchToProps = (dispatch) => {
	return {
		signOut: (payload) => {
			dispatch(signOut(payload));
		},
		toggleSearchForm: (payload) => dispatch(toggleSearchForm(payload)),
		setSearchFormId: (payload) => dispatch(setSearchFormId(payload)),
	};
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(Navigation))
);
