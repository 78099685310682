import cloneDeep from 'lodash.clonedeep'
import { 
    INIT,
    SET_EVENT_INFO_FORM,
    EVENT_INFO_FORM_UPDATE_FIELD,
    RESET_EVENT_INFO_FORM
} from '../reducers/eventInfoForm';


export const reset = () => {
  return {
    type: RESET_EVENT_INFO_FORM,
    payload: INIT
  }
}

export const set = form => {
  if (form) {
    return {
      type: SET_EVENT_INFO_FORM,
      payload: form
    }
  }
  // Empty user in store if no user is sent
  return {
    type: SET_EVENT_INFO_FORM,
    payload: INIT
  }
}

export const updateField = ({ Field, Value, Valid=true, ExpectedType }) => (dispatch, getState) => {
  const { eventInfoForm } = cloneDeep(getState());

  // Check and make sure the type of field is the same as the INIT
  
  if(Value !== null && Value !== undefined) {
    if(typeof INIT[Field].value === typeof Value || ExpectedType === typeof Value) {
      eventInfoForm[Field].value = Value
    }
  }
  eventInfoForm[Field].valid = Valid;

  dispatch({
    type: EVENT_INFO_FORM_UPDATE_FIELD,
    payload: eventInfoForm
  })

  return eventInfoForm;
  
}
