export const SET_SAVED_PROVIDER = 'SET_SAVED_PROVIDER';

export const INIT = null;

export default function(state = INIT, { type, payload }) {

  switch(type) {
    case SET_SAVED_PROVIDER:
      return payload;
    default:
      return state;
  }
}