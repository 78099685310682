const styles = theme => ({
    root: {
        padding: 24,
    },
    forms: {
        padding: 0,
        margin: '30px 0px'
    },
    formsHeader: {
        display: 'flex',
        justifyContent: 'center'
    },
    formsHeaderText: {
        fontFamily: 'Roboto',
        fontSize: 18,
        fontWeight: 500
    }
});
export default styles;