import { toolbarHeight } from "../../styles";

const styles = theme => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            padding: 8
        },
        [theme.breakpoints.up('md')]: {
            padding: 24
        },
        
        height: `calc(100% - ${toolbarHeight + theme.spacing(2)}px)`
    }
});

export default styles;