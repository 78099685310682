import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Button            from '../../../../../components/Button';
import Select             from '../../../../../components/Input/Select';
import Dialog            from '@material-ui/core/Dialog';
import DialogTitle       from '@material-ui/core/DialogTitle';
import DialogContent     from '@material-ui/core/DialogContent';
import DialogActions     from '@material-ui/core/DialogActions';
import { 
    Slide,
    MenuItem
} from '@material-ui/core'

import styles from './styles';
import {
    eventTypes,
    severities,
    timeFrames
} from './selects'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Modal = props => {
    const { classes, open, onClose, onSubmit, setSnackbar } = props;

    const [type, setType] = useState('')
    const [category, setCategory] = useState('')
    const [severity, setSeverity] = useState('')
    const [timeFrame, setTimeFrame] = useState('')

    function handleSubmit() {
        if(!type || !category || !severity || !timeFrame) {
            return setSnackbar("Please fill out all fields")
        }

        onSubmit({ type, category, severity, timeFrame });
        setType('');
        setCategory('');
        setSeverity('');
        setTimeFrame('');
        onClose();
    }

    return (
        <div className={classes.root}>
            <Dialog
                fullWidth
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle classes={{root: classes.title}} id="alert-dialog-title" disableTypography={true}>
                    Add Event
                </DialogTitle>
                <DialogContent classes={{root: classes.content}}>
                    <Select
                        id='type'
                        marginBottom={4}
                        InputLabelProps={{
                            classes: { root: classes.inputLabel }
                        }}
                        variant="filled"
                        disableUnderline={true}
                        onChange={(e) => { setType(e.target.value); setCategory('') }}
                        value={type}
                        errortext={''} 
                        label="Event Type:"
                    >
                        {
                            Object.keys(eventTypes).map(key => (
                                <MenuItem key={key} value={key}>
                                    {key}
                                </MenuItem>
                            ))
                        }
                    </Select>
                    <Select
                        id='category'
                        marginBottom={4}
                        InputLabelProps={{
                            classes: { root: classes.inputLabel }
                        }}
                        variant="filled"
                        disableUnderline={true}
                        onChange={(e) => setCategory(e.target.value)}
                        value={category}
                        errortext={''} 
                        label="Event Category:"
                    >
                        {
                            eventTypes[type] ? eventTypes[type].map(key => (
                                <MenuItem key={key} value={key}>
                                    {key}
                                </MenuItem>
                            )) : <MenuItem value={"N/A"}> Select an event type first </MenuItem>
                        }
                    </Select>
                    <Select
                        id='severity'
                        marginBottom={4}
                        InputLabelProps={{
                            classes: { root: classes.inputLabel }
                        }}
                        variant="filled"
                        disableUnderline={true}
                        onChange={(e) => setSeverity(e.target.value)}
                        value={severity}
                        errortext={''} 
                        label="Severity:"
                    >
                        {
                            severities.map(key => (
                                <MenuItem key={key} value={key}>
                                    {key}
                                </MenuItem>
                            ))
                        }
                    </Select>
                    <Select
                        id='timeFrame'
                        marginBottom={4}
                        InputLabelProps={{
                            classes: { root: classes.inputLabel }
                        }}
                        variant="filled"
                        disableUnderline={true}
                        onChange={(e) => setTimeFrame(e.target.value)}
                        value={timeFrame}
                        errortext={''} 
                        label="Time Frame:"
                    >
                        {
                            timeFrames.map(key => (
                                <MenuItem key={key} value={key}>
                                    {key}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </DialogContent>
                <DialogActions>
                    <Button 
                        text="Add" 
                        onClick={handleSubmit}
                        color="primary"
                        size="medium"
                        fullWidth={true}
                        variant="contained" />
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default withStyles(styles)(Modal);
