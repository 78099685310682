import { Auth } from 'aws-amplify';
// AWS Project Constants
const awsmobile = {
  Auth: {
    identityPoolId: 'us-west-2:6941cc02-805d-42a0-99c8-be0812c4736d',
    region: 'us-west-2',
    userPoolId: 'us-west-2_WEcVjNYBA',
    userPoolWebClientId: '281dvt2afe0pf9csinoffjto9t'
  },
  API: {
    endpoints: [
      {
        name: 'ASDAPatientOutcomeAPI',
        endpoint: 'https://dev.gateway.asdaqr.org',
        region: 'us-west-2',
        custom_header: async () => {
          // return { Authorization : 'token' } 
          // Alternatively, with Cognito User Pools use this:
          return {
            Authorization: (await Auth.currentSession()).idToken.jwtToken
          }
        }
      }
    ]
  }
};

export default awsmobile;