import React                        from 'react';
import { Redirect, Route, Switch }  from 'react-router-dom';
import { connect }                  from 'react-redux';
import { setSnackbar } from '../../redux/actions/snackbar'

import checkError from '../../modules/check-error';
import Navigation   from '../../components/Navigation'
import Confirmation from '../Authenticated/Confirmation'
import FormPage     from '../Authenticated/FormPage'
import Dashboard     from '../Authenticated/Dashboard'

const UserTypeApp = (props) => {
    const { setSnackbar } = props;

    function handleError(error) {
        setSnackbar(checkError(error));
    }

    const _Confirmation = (props) => <Confirmation onError={handleError} {...props} />
    const _FormPage = (props) => <FormPage onError={handleError} {...props} />;
    const _Dashboard = (props) => <Dashboard onError={handleError} {...props} />;


    return(
        <Navigation>
            <Switch>
                <Route exact path='/confirmation' component={_Confirmation} />
                <Route exact path='/form/:id' component={_FormPage} />
                <Route exact path='/' component={_Dashboard} />
                <Route path='/*' render={() => <Redirect to='/' />} />
            </Switch>
        </Navigation>
    );
}

const mapStateToProps = ({ user }) => ({ user });
const actionCreators = { setSnackbar };

export default (connect(mapStateToProps, actionCreators)(UserTypeApp));

