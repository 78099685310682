import cloneDeep from 'lodash.clonedeep';
import {
    INIT,
    SET_PROVIDER_INFO_FORM,
    PROVIDER_INFO_FORM_UPDATE_FIELD,
    RESET_PROVIDER_INFO_FORM,
} from '../reducers/providerInfoForm';

export const reset = () => (dispatch, getState) => {
    let { savedProviderInfo } = cloneDeep(getState());
    if (savedProviderInfo) {
        return dispatch({
            type: RESET_PROVIDER_INFO_FORM,
            payload: savedProviderInfo,
        });
    }

    return dispatch({
        type: RESET_PROVIDER_INFO_FORM,
        payload: INIT,
    });
};

export const set = (form) => {
    console.log('Set provider info form', form);
    if (form) {
        return {
            type: SET_PROVIDER_INFO_FORM,
            payload: form,
        };
    }
    // Empty user in store if no user is sent
    return {
        type: SET_PROVIDER_INFO_FORM,
        payload: INIT,
    };
};

export const updateField = ({ Field, Value, Valid = true, ExpectedType }) => (dispatch, getState) => {
    const { providerInfoForm } = cloneDeep(getState());

    // Check and make sure the type of field is the same as the INIT
    if (Value !== null && Value !== undefined) {
        if (typeof INIT[Field].value === typeof Value || ExpectedType === typeof Value) {
            providerInfoForm[Field].value = Value;
        }
    }

    providerInfoForm[Field].valid = Valid;

    dispatch({
        type: PROVIDER_INFO_FORM_UPDATE_FIELD,
        payload: providerInfoForm,
    });

    return providerInfoForm;
};
