import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    Typography,
    CircularProgress,
} from '@material-ui/core';
import { submit, update, get, reset, resubmit } from '../../../redux/actions/form';

import { updateField as updateEventInfoField } from '../../../redux/actions/eventInfoForm';
import { updateField as updateProviderInfoField } from '../../../redux/actions/providerInfoForm';
import { updateField as updatePatientInfoField } from '../../../redux/actions/patientInfoForm';

import { setSnackbar } from '../../../redux/actions/snackbar';
import ExpandMoreIcon from '@material-ui/icons/ArrowDropDown';
import styles from './styles';
import Button from '../../../components/Button';
import checkError from '../../../modules/check-error';
import FormValidator from '../../../modules/FormValidator';

import ProviderInfo from './ProviderInfo';
import PatientInfo from './PatientInfo';
import EventInfo from './EventInfo';
import EventStickyInfo from './EventStickyInfo';

const FormPage = (props) => {
    const {
        classes,
        submit,
        setSnackbar,
        form,
        get,
        reset,
        update,
        resubmit,
        updateEventInfoField,
        updatePatientInfoField,
        updateProviderInfoField,
    } = props;

    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(false);
    const errorMsgQueue = useRef([]).current;

    const providerInfoRefs = {
        // providerId: React.createRef(),
        providerTitle: React.createRef(),
        coverageCode: React.createRef(),
        facilityId: React.createRef(),
        location: React.createRef(),
        admissionStatus: React.createRef(),
        DOS: React.createRef(),
        procedureStatus: React.createRef(),
    };

    const patientInfoRefs = {
        patientId: React.createRef(),
        dateOfBirth: React.createRef(),
        age: React.createRef(),
        gender: React.createRef(),
        bmi: React.createRef(),
        pounds: React.createRef(),
        kilograms: React.createRef(),
        asa: React.createRef(),
        anesthesiaType: React.createRef(),
        paymentMethod: React.createRef(),
        icd10: React.createRef(),
        CPTAnesthesiaCodes: React.createRef(),
        CPTSurgeryCodes: React.createRef(),
    };

    const eventRefs = {
        procedureStartTime: React.createRef(),
        procedureEndTime: React.createRef(),
        anesthesiaStartTime: React.createRef(),
        anesthesiaEndTime: React.createRef(),
        surgeryStartTime: React.createRef(),
        surgeryEndTime: React.createRef(),
        timeReadyDischarge: React.createRef(),
        Events: React.createRef(),
    };

    async function validateOnFind({ form, eRefs, pRefs, prRefs }) {
        // if (!form.finished) return; // Only validate on finished forms
        try {
            FormValidator(prRefs, updateProviderInfoField);
            FormValidator(pRefs, updatePatientInfoField);
            FormValidator(eRefs, updateEventInfoField);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        async function _getForm() {
            console.log('Get form called');
            setLoading(true);
            try {
                await get(props.match.params.id);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                setSnackbar(`Form was not found`);
                props.history.replace('/');
            }
        }

        if (props.match.params.id !== 'new') {
            _getForm();
        } else {
            if (form.id) {
                reset();
            }
        }
    }, [get, props.history, reset, setSnackbar, props.match]);

    useEffect(() => {
        if (props.match.params.id !== 'new') {
            // Validate form after it has loaded.
            const eRefs = { ...eventRefs };
            const pRefs = { ...patientInfoRefs };
            const prRefs = { ...providerInfoRefs };
            validateOnFind({ eRefs, pRefs, prRefs });
        }
    }, [form])

    async function handleResubmit() {
        let invalidFields = FormValidator(eventRefs, updateEventInfoField);
        if (invalidFields.length > 0) {
            props.onError(errorMsgQueue.shift());
            return errorMsgQueue.length = 0;
        }

        invalidFields = FormValidator(patientInfoRefs, updatePatientInfoField);
        if (invalidFields.length > 0) {
            let _invalidField = invalidFields.shift();
            return props.onError(_invalidField.message);
        }

        invalidFields = FormValidator(providerInfoRefs, updateProviderInfoField);
        if (invalidFields.length > 0) {
            let _invalidField = invalidFields.shift();
            return props.onError(_invalidField.message);
        }

        setSubmitting(true);
        try {
            // TODO: Make a redux function for resubmitting
            await resubmit(form.id);
            setSnackbar(`Form was resubmitted successfully`);
            reset();

            setSubmitting(false);
            props.history.push('/');
        } catch (error) {
            setSubmitting(false);
            setSnackbar(
                `There were one or more errors, please review each field before submitting again: ${checkError(error)}`
            );
        }
    }

    async function handleSubmit(finished = true) {
        if (finished) {
            let invalidFields = FormValidator(eventRefs, updateEventInfoField);
            if (invalidFields.length > 0) {
                props.onError(errorMsgQueue.shift());
                return errorMsgQueue.length = 0;
            }

            invalidFields = FormValidator(patientInfoRefs, updatePatientInfoField);
            if (invalidFields.length > 0) {
                let _invalidField = invalidFields.shift();
                return props.onError(_invalidField.message);
            }

            invalidFields = FormValidator(providerInfoRefs, updateProviderInfoField);
            if (invalidFields.length > 0) {
                let _invalidField = invalidFields.shift();
                return props.onError(_invalidField.message);
            }
        }

        setSubmitting(true);
        try {
            if (props.match.params.id === 'new') {
                await submit(finished);
                setSnackbar(`Form was ${finished ? 'Submitted' : 'Saved'} successfully`);
                reset();
            } else {
                await update(finished);
                setSnackbar('Form was updated successfully');
            }

            setSubmitting(false);
            if (finished) props.history.push('/confirmation');
            else props.history.push('/');
        } catch (error) {
            setSubmitting(false);
            setSnackbar(
                `There were one or more errors, please review each field before submitting again: ${checkError(error)}`
            );
        }
    }

    if (loading) {
        return (
            <div className={classes.progress}>
                <CircularProgress color="primary" />
            </div>
        );
    }

    return (
        <div className={classes.root}>
            {/* Provider Panel */}
            <ExpansionPanel classes={{ expanded: classes.expandedPanel, root: classes.panel }} defaultExpanded={true}>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.providerPanelTitle}>
                    <Typography className={classes.heading}>Provider Info</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails classes={{ root: classes.panelDetails }}>
                    <ProviderInfo refs={providerInfoRefs} />
                </ExpansionPanelDetails>
            </ExpansionPanel>

            {/* Patient Panel */}
            <ExpansionPanel classes={{ expanded: classes.expandedPanel, root: classes.panel }} defaultExpanded={true}>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.patientPanelTitle}>
                    <Typography className={classes.heading}>Patient Info</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails classes={{ root: classes.panelDetails }}>
                    <PatientInfo refs={patientInfoRefs} />
                </ExpansionPanelDetails>
            </ExpansionPanel>

            {/* Event Panel */}
            <ExpansionPanel classes={{ expanded: classes.expandedPanel, root: classes.panel }} defaultExpanded={true}>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.eventPanelTitle}>
                    <Typography className={classes.heading}>Events</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails classes={{ root: classes.panelDetails }}>
                    <EventInfo refs={eventRefs} />
                </ExpansionPanelDetails>
            </ExpansionPanel>

            {!!form.finished.value !== true ? (
                <div className={classes.buttons}>
                    <Button
                        text="Submit"
                        onClick={() => handleSubmit(true)}
                        color="primary"
                        size="large"
                        disabled={submitting || !!form.finished.value}
                        fullWidth={true}
                        variant="contained"
                    />
                    <div className={classes.secondaryButtons}>
                        <Button
                            text="Go Back"
                            className={classes.secondaryButton}
                            onClick={() => props.history.push('/')}
                            color="secondary"
                            size="large"
                            disabled={submitting}
                            fullWidth={true}
                            variant="contained"
                        />
                        <Button
                            text="Save"
                            className={classes.secondaryButton}
                            onClick={() => handleSubmit(false)}
                            color="secondary"
                            size="large"
                            disabled={submitting || !!form.finished.value}
                            fullWidth={true}
                            variant="contained"
                        />
                    </div>
                </div>
            ) : (
                <div className={classes.buttons}>
                    <div className={classes.secondaryButtons}>
                        <Button
                            text="Go Back"
                            className={classes.secondaryButton}
                            onClick={() => props.history.push('/')}
                            color="secondary"
                            size="large"
                            disabled={submitting}
                            fullWidth={true}
                            variant="contained"
                        />
                        <Button
                            text="Resubmit"
                            className={classes.secondaryButton}
                            onClick={() => handleResubmit(false)}
                            color="primary"
                            size="large"
                            disabled={submitting}
                            fullWidth={true}
                            variant="contained"
                        />
                    </div>
                </div>
            )}

            <EventStickyInfo refs={eventRefs} errorMsgQueue={errorMsgQueue} />
        </div>
    );
};

const mapStateToProps = ({ form }) => ({ form });
const actionCreators = {
    submit,
    get,
    resubmit,
    reset,
    setSnackbar,
    update,
    updateEventInfoField,
    updatePatientInfoField,
    updateProviderInfoField,
};

export default connect(mapStateToProps, actionCreators)(withStyles(styles)(FormPage));
