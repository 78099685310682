import { SET_SEARCH_FORM } from '../actions/searchForm';

export const INIT = {
	id: '',
	open: false,
};

export default function (state = INIT, { type, payload }) {
	let newState = Object.assign({}, state, payload);

	switch (type) {
		case SET_SEARCH_FORM:
			return newState;
		default:
			return state;
	}
}
