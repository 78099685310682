import React, { useEffect, useState } from 'react';
import { connect }    from 'react-redux'
import { updateField } from '../../../../redux/actions/eventInfoForm'
import { setSnackbar } from '../../../../redux/actions/snackbar'

import { withStyles } from '@material-ui/core/styles';
import { 
  Grid, 
  Typography
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add'

import AddEventDialog from './AddEventDialog'
import Button from '../../../../components/Button';
import Input from '../../../../components/Input'
import Page  from '../../../../components/Page';

import EventListItem from './EventListItem/EventListItem';

import styles from './styles';

const EventInfo = (props) => {
  const { classes, eventInfoForm, updateField, setSnackbar, refs } = props;
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
  }, []);

  function handleAddEvent(event) {
    updateField({ 
      Field: 'Events', 
      Value: [event, ...eventInfoForm.Events.value]
    })
  }

  return (
    <Page classes={{root: classes.root}}>
      <AddEventDialog 
        open={dialogOpen} 
        onClose={() => setDialogOpen(false)} 
        onSubmit={handleAddEvent}
        setSnackbar={setSnackbar} />
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          <Input
            style={{display: 'none'}}
            ref={el => refs.Events = el}
            error={!eventInfoForm.Events.valid} 
            // errorMessage={"Please provide atleast 1 Event"}
            // validator={() => eventInfoForm.Events.value.length > 0}
          />
          <Typography className={classes.title} variant={"h6"}>Events</Typography>
          {
            eventInfoForm.Events.value.length > 0 ?
            eventInfoForm.Events.value.map((event, index) => (
              <EventListItem
                key={`event-${index}`}
                event={event}
                onDelete={() => updateField({ 
                  Field: 'events', 
                  Value: eventInfoForm.events.value.filter((_, idx) => idx !== index)
                })} />
            ))
            :
            <div className={classes.noEvents}>
              <Typography className={classes.noEventsText}> No Events Documented </Typography>
            </div>
          }
          <Button 
            text="Add New Event" 
            color="primary"
            size="large"
            className={ classes.addNewEventButton }
            onClick={() => setDialogOpen(true)}
            fullWidth={true}
            variant="contained"
            beforeIcon={<AddIcon style={{paddingRight: 10, fontSize: 16}}/>} />
        </Grid>
      </Grid>
    </Page>
  );
}

const mapStateToProps = ({ eventInfoForm }) => ({ eventInfoForm })
const actionCreators = { updateField, setSnackbar };

export default connect(mapStateToProps, actionCreators)(withStyles(styles)(EventInfo));