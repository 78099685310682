const styles = theme => ({
    root: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    providerPanelTitle: {
        backgroundColor: "#63c2e2",
    },
    patientPanelTitle: {
        backgroundColor: '#34a9d0',
    },
    eventPanelTitle: {
        backgroundColor: '#208eb3',
    },
    heading: {
        color: 'white',
        fontFamily: "Roboto",
        fontSize: 15,
        fontWeight: 700
    }, 
    expandedPanel: {
        margin: '2px 0px !important'
    },
    panel: {
        marginTop: '2px'
    },
    panelDetails: {
        display: 'block',
    },
    buttons: {
        padding: '15px 30px 15px 30px'
    },
    secondaryButtons: {
        display: 'flex',
        marginTop: 8,
        marginLeft: -4,
        marginRight: -4
    },
    secondaryButton: {
        marginLeft: 4,
        marginRight: 4
    },
    progress: {
        height: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});
export default styles;