const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    title: {
        fontfamily: 'Roboto Light',
        fontSize: 34,
        fontWeight: 300,
        marginBottom: 28,
        color: '#009acd',
        letterSpacing: -0.82,
        marginTop : 0
    },
    subtitle: {
        color: '#000000',
        fontfamily: 'Roboto',
        fontWeight: 200,
    
        fontSize: 13,
        letterSpacing: -0.31,
        lineHeight: 0,
        margin: 0

    },
    topContent: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    bottomContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    form: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    stepText: {
        color: '#b8b8b8',
        fontFamily: 'Roboto',
        fontSize: 15,
        fontWeight: 400,
        marginTop: 4
    },
    goBackButton: {
        marginRight: 2,
        display: 'flex',
        flexDirection: 'row',
        width: 291,
        height: 38,

    },
});
export default styles;