import moment from 'moment';

function handleValidateDates(sDate, eDate, date) {
    const startDate = moment(new Date(sDate));
    const endDate = moment(new Date(eDate));

    if (date === 'start' && !startDate._isValid) return false;
    if (date === 'end' && !endDate._isValid) return false;

    if (startDate.isBefore(endDate)) return true;

    return false;
}

export default handleValidateDates;
