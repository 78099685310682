const styles = theme => ({
    root: {
        // maxWidth: 400,
        width: '100%',

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    }
});

export default styles;