import { combineReducers } from 'redux';

// Import reducers here
import cognitoUser          from './cognitoUser';
import eventInfoForm        from './eventInfoForm';
import frequentlyUsedTerms        from './frequentlyUsedTerms';
import form                 from './form';
import providerInfoForm     from './providerInfoForm';
import patientInfoForm      from './patientInfoForm';
import savedProviderInfo    from './savedProviderInfo';
import snackbar             from './snackbar';
import user                 from './user';
import modal                from './modal';
import searchForm                from './searchForm';

const rootReducer = combineReducers({
  cognitoUser,
  eventInfoForm,
  frequentlyUsedTerms,
  form,
  modal,
  patientInfoForm,
  providerInfoForm,
  savedProviderInfo,
  snackbar,
  user,
  searchForm,
});

export default rootReducer;
